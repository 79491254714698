export function formatConveniosToShow(plans) {
  const convenios = []

  if (!plans) {
    return convenios
  }

  plans.forEach((plan) => {
    const {
      id,
      idConvenio,
      idPaciente,
      nmConvenio,
      dtCadastro,
      cartaoPaciente,
      idAcomodacaoConvenio,
      descAcomodacaoConvenio
    } = plan

    if (cartaoPaciente) {
      cartaoPaciente.forEach((card) => {
        const { idPacienteConvenio, numeroCartao } = card

        convenios.push({
          id,
          idConvenio,
          idPaciente,
          nmConvenio,
          dtCadastro,
          numeroCartao,
          idPacienteConvenio,
          idAcomodacaoConvenio,
          descAcomodacaoConvenio,
          isNew: false
        })
      })
    } else {
      convenios.push({
        id,
        idConvenio,
        idPaciente,
        nmConvenio,
        dtCadastro,
        idPacienteConvenio: null,
        idAcomodacaoConvenio,
        descAcomodacaoConvenio,
        isNew: false
      })
    }
  })

  return convenios
}

export function formatConveniosToSubmit(plans) {
  const convenios = []

  if (!plans) {
    return convenios
  }

  plans.forEach((plan) => {
    const planIndex = convenios.findIndex(
      (convenio) =>
        convenio.idConvenio === plan.idConvenio || convenio.id === plan.id
    )

    if (planIndex >= 0) {
      if (plan.numeroCartao) {
        convenios[planIndex].cartaoPaciente.push({
          numeroCartao: plan.numeroCartao,
          isNew: plan.isNew,
          status: 0,
          idAcomodacaoConvenio: plan.idAcomodacaoConvenio,
          descAcomodacaoConvenio: plan.descAcomodacaoConvenio
        })
      }
    } else {
      convenios.push({
        id: plan.id,
        isNew: plan.isNew,
        status: 0,
        descAcomodacaoConvenio: plan.descAcomodacaoConvenio,
        idAcomodacaoConvenio: plan.idAcomodacaoConvenio,
        idConvenio: plan.idConvenio,
        idPaciente: plan.idPaciente, // Verificar se idPaciente está definido
        dtCadastro: new Date().toLocaleDateString('en-CA'),
        cartaoPaciente: plan.numeroCartao
          ? [{ numeroCartao: plan.numeroCartao, status: 0 }]
          : ''
      })
    }
  })

  return convenios
}

export function formatDate(date, format) {
  const newDateFormat = date ? new Date(date) : new Date()
  if (!isNaN(newDateFormat)) {
    return newDateFormat.toLocaleDateString(format)
  } else {
    return 'dd/mm/aaaa'
  }
}

export function formatHour(hourUnformated) {
  let [hour, time] = hourUnformated.split(' ')
  if (time) {
    return time === 'AM' && hour[0] === '1' && hour[1] === '2'
      ? `00:${hour[3]}${hour[4]}`
      : time === 'PM' && hour[0] === '1' && hour[1] === '2'
      ? hour
      : time === 'AM' && hour.length <= 4
      ? (hour = `0${hour}`)
      : time === 'AM'
      ? hour
      : time === 'PM' && hour.length <= 4 && parseInt(hour[0]) + 2 < 10
      ? (hour = `1${parseInt(hour[0]) + 2}:${hour[2]}${hour[3]}`)
      : time === 'PM' && hour.length <= 4
      ? (hour = `2${parseInt(hour[0]) - 8}:${hour[2]}${hour[3]}`)
      : (hour = `2${parseInt(hour[0] + hour[1]) - 8}:${hour[3]}${hour[4]}`)
  } else {
    return hourUnformated
  }
}

export function formatDateHour(date) {
  const dateInstance = date ? new Date(date) : new Date()

  const dateToFormat = dateInstance.toLocaleDateString()
  const hour = dateInstance.toLocaleTimeString()

  const formattedDate = dateToFormat
    .replaceAll('/', '-')
    .split('-')
    .reverse()
    .join('-')

  return `${formattedDate}T${hour}`
}

export function formatLocalDateHour(date) {
  const dateInstance = date ? new Date(date) : new Date()
  const localDate = dateInstance.toLocaleDateString()
  const localHour = dateInstance.toLocaleTimeString().substring(0, 5)

  return `${localDate} ${localHour}`
}

export function formatSystemDate(date) {
  const dateInstance = date ? new Date(date) : new Date()

  const dateToFormat = dateInstance.toLocaleDateString()

  const formattedDate = dateToFormat
    .replaceAll('/', '-')
    .split('-')
    .reverse()
    .join('-')

  return `${formattedDate}`
}

export function formatCurrentTime() {
  const date = new Date()

  const insertZeroIfTimeHaveOnlyOneNumber = (number) => {
    const string = number.toString()

    if (string.length <= 1) {
      return `0${number}`
    } else {
      return number
    }
  }

  const minute = insertZeroIfTimeHaveOnlyOneNumber(date.getMinutes())
  const hour = insertZeroIfTimeHaveOnlyOneNumber(date.getHours())

  return `${hour}:${minute}`
}

export function removeTagsFromString(string) {
  return string
    .replace(/(<br\s*\/?>\s*){2,}/gi, '\n') // Substitui 2 ou mais <br> seguidos por uma única quebra de linha
    .replace(/<[^>]+>/g, '') // Remove todas as outras tags HTML
    .replace(/<\/div>/gi, '\n') // Substitui </div> por \n
}
export function validateCns(cns) {
  cns = cns.replaceAll('.', '')

  if (cns.trim().length !== 15) {
    return false
  }

  const initial = cns.substring(0, 1)

  if (['1', '2'].includes(initial)) {
    return cnsStartsWith1Or2(cns)
  } else if (['7', '8', '9'].includes(initial)) {
    return cnsStartsWith7Or8Or9(cns)
  }
}

function cnsStartsWith1Or2(cns) {
  let soma = 0
  let resto = 0
  let dv = 0
  let resultado
  const pis = cns.substring(0, 11)

  for (let count = 0; count <= 10; count++) {
    soma = soma + parseInt(pis.substring(count, count + 1)) * (15 - count)
  }

  resto = soma % 11
  dv = 11 - resto

  if (dv === 11) {
    dv = 0
  }

  if (dv === 10) {
    soma = 0
    for (let count = 0; count <= 10; count++) {
      soma = soma + parseInt(pis.substring(count, count + 1)) * (15 - count)
    }

    soma = soma + 2

    resto = soma % 11
    dv = 11 - resto

    resultado = pis + '001' + dv.toString()
  } else {
    resultado = pis + '000' + dv.toString()
  }

  return cns === resultado
}

function cnsStartsWith7Or8Or9(cns) {
  let soma = 0
  let resto = 0

  for (let count = 0; count <= 14; count++) {
    soma = soma + parseInt(cns.substring(count, count + 1)) * (15 - count)
  }

  resto = soma % 11

  return resto === 0
}
