<template>
  <b-modal
    id="modalAgendInBlock"
    header-bg-variant="primary"
    title="Alteração em Bloco"
    hide-footer
    size="xl"
    title-class="text-light"
    @close="close()"
    @hidden="close()"
  >
    <div class="container-rec">
      <div class="box-days">
        <b-form-checkbox
          @change="isDisabledButton()"
          v-model="allDaysUtils"
          :value="1"
          :unchecked-value="0"
          switch
        >
          Selecionar dias úteis
        </b-form-checkbox>
        <div class="list-days">
          <b-form-checkbox
            @change="isDesativateAll(item)"
            v-for="item in listWeek"
            :key="item.id"
            :id="`checkbox-${item.dia}`"
            v-model="item.value"
            :value="1"
            :unchecked-value="0"
          >
            {{ item.dia }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="box-period">
        <div class="box-date">
          <div class="standard">
            <label>Data inicial:</label>
            <div style="display: flex; align-items: center; gap: 12px">
              <div class="box-input">
                <input
                  :min="today"
                  v-model="dataInit"
                  type="date"
                  @change="updateMaxDate"
                />
                <div v-if="valid1" style="font-size: 0.6rem; color: red">
                  <span>Informe uma data de inicio</span>
                </div>
              </div>

              <div class="box-input">
                <input
                  title="O horário não pode ser maior que a hora atual!"
                  v-model="dataTime1"
                  :min="dataTime1"
                  type="time"
                  style="width: 90px"
                />
                <div
                  v-if="valid2"
                  style="font-size: 0.6rem; color: red; margin-top: 4px"
                >
                  <span>Informe um horário</span>
                </div>
              </div>
            </div>
          </div>
          <div class="standard">
            <label>Data final:</label>
            <div style="display: flex; align-items: center; gap: 12px">
              <div class="box-input">
                <input :max="maxDate" v-model="dataEnd" type="date" />
                <div
                  v-if="valid3"
                  style="font-size: 0.6rem; color: red; margin-top: 4px"
                >
                  <span>Informe uma data fim</span>
                </div>
              </div>

              <div class="box-input">
                <input v-model="dataTime2" type="time" style="width: 90px" />
                <div
                  v-if="valid4"
                  style="font-size: 0.6rem; color: red; margin-top: 4px"
                >
                  <span>Informe um horário</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="standard">
          <label>Clinica:</label>
          <v-select
            class="v-select"
            v-model="clinic"
            :options="clinics"
            label="nmClinica"
            :clearable="false"
          ></v-select>
        </div>
        <div class="standard">
          <label>Profissional:</label>
          <v-select
            class="v-select"
            v-model="doctor"
            :options="doctors"
            label="nmUsuario"
            :clearable="false"
          ></v-select>
        </div>
      </div>
      <div class="box-search">
        <div class="box-options">
          <b-form-checkbox
            @change="isDisabledButton()"
            v-model="allOptions"
            :value="1"
            :unchecked-value="0"
            switch
          >
            Selecionar todos
          </b-form-checkbox>
          <b-form-checkbox
            @change="isDisabledButton()"
            v-for="item in listStatus"
            :key="item.id"
            :id="`checkbox-${item.id}`"
            v-model="item.value"
            :value="1"
            :unchecked-value="0"
          >
            {{ item.status }}
          </b-form-checkbox>
        </div>
        <button
          :disabled="isDisabled || isLoading"
          :class="
            isDisabled || isLoading
              ? 'button-search-deactivate'
              : 'button-search'
          "
          @click="getData()"
        >
          Pesquisar
          <span v-if="!isLoading"></span>
          <b-spinner v-else small></b-spinner>
        </button>
      </div>
      <div v-if="isMobile()" class="box-list-cards">
        <div v-for="(item, index) in dataList" :key="index" class="list-card">
          <div v-if="dataList.length !== 0" class="box-info-card">
            <div class="content">
              <b-form-checkbox
                :id="`checkbox-${index}`"
                v-model="item.check"
                :value="1"
                :unchecked-value="0"
              >
              </b-form-checkbox>
            </div>
            <div class="content">
              <label>Status da agenda:</label>
              {{ item.situacao_agendamento }}
            </div>
            <div class="content">
              <label>Dia da semana:</label>
              {{ item.diaSemana }}
            </div>
            <div class="content">
              <label>Data:</label>
              {{ this.formartDateInclude(item.dt_Agendamento) }}
            </div>
            <div class="content">
              <label>Horário:</label>
              {{ item.hora_agendamento }}
            </div>
            <button @click="removeItem(index)" class="button-remove">
              Remover
            </button>
          </div>
        </div>
      </div>
      <div v-else class="table-container">
        <b-table-simple hover>
          <b-thead>
            <b-tr>
              <b-th class="table-header">
                <b-form-checkbox
                  id="checkbox-allList"
                  v-model="allList"
                  :value="1"
                  :unchecked-value="0"
                >
                </b-form-checkbox>
              </b-th>
              <b-th class="table-header"> Status Agenda </b-th>
              <b-th class="table-header"> Dia Semana </b-th>
              <b-th class="table-header"> Data </b-th>
              <b-th class="table-header"> Horário </b-th>
              <b-th class="table-header"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in dataList" :key="index">
              <b-td>
                <b-form-checkbox
                  :id="`checkbox-${item.id_clinica}-${index++}`"
                  v-model="item.check"
                  :value="1"
                  :unchecked-value="0"
                >
                </b-form-checkbox
              ></b-td>
              <b-td> {{ item.situacao_agendamento }}</b-td>
              <b-td> {{ item.diaSemana }}</b-td>

              <b-td>
                {{ formartDateInclude(item.dt_Agendamento) }}
              </b-td>

              <b-td> {{ item.hora_agendamento }}</b-td>

              <b-td>
                <button @click="removeItem(index)" class="button-remove">
                  Remover
                </button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <div
        style="
          display: flex;
          width: 100%;
          justify-content: flex-end;
          align-items: center;
          gap: 20px;
        "
      >
        <div class="content">
          <label style="font-weight: 400; color: black"
            >Alterar lista para:</label
          >
          <v-select
            style="width: 260px"
            v-model="status"
            :options="listStatus"
            label="status"
            :clearable="false"
          ></v-select>
        </div>

        <button
          :disabled="checkDataList()"
          @click="getExecutedRecorrencia()"
          class="button-rec"
        >
          Executar alteração
          <span>
            <b-spinner
              v-show="loading === true"
              small
              variant="ligth"
            ></b-spinner>
          </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment'
import API from '../../services/index'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      dataList: [],
      maxDate: null,
      today: new Date().toISOString().split('T')[0],
      isDisabled: true,
      isLoading: false,
      allList: 0,
      dataInit: this.firstDayOfMonth(),
      dataEnd: this.getFinalyofMonth(),

      valid1: false,
      valid2: false,
      valid3: false,
      valid4: false,

      dataTime1: moment(new Date()).format('HH:mm'),
      dataTime2: '23:59',
      loading: false,

      status: '',
      clinic: '',
      clinics: [],

      doctor: '',
      doctors: [],

      // DIAS DA SEMANA
      listWeek: [
        {
          dia: 'Segunda-feira',
          id: 1,
          value: 0
        },
        {
          dia: 'Terça-feira',
          id: 2,
          value: 0
        },
        {
          dia: 'Quarta-feira',
          id: 3,
          value: 0
        },
        {
          dia: 'Quinta-feira',
          id: 4,
          value: 0
        },
        {
          dia: 'Sexta-feira',
          id: 5,
          value: 0
        },
        {
          dia: 'Sabado',
          id: 6,
          value: 0
        },
        {
          dia: 'Domingo',
          id: 0,
          value: 0
        }
      ],
      allDaysUtils: 0,

      // Opções para pesquisa
      allOptions: 0,
      listStatus: [
        {
          status: 'Livre',
          id: 0,
          value: 0
        },
        {
          status: 'Bloqueado',
          id: 6,
          value: 0
        },
        {
          status: 'Internet Presencial',
          id: 18,
          value: 0
        },
        {
          status: 'Internet Teleconsulta',
          id: 19,
          value: 0
        }
      ]
    }
  },
  mounted() {
    this.$bvModal.show('modalAgendInBlock')
    window.addEventListener('keydown', this.handleKeyPress)
    this.initComponent()
    this.updateMaxDate()
    this.status = this.listStatus[0]
    this.allDaysUtils = 1
    this.allOptions = 0
  },
  watch: {
    allList(value) {
      if (value === 1) {
        this.dataList.forEach((item) => {
          item.check = 1
        })
      } else {
        this.dataList.forEach((item) => {
          item.check = 0
        })
      }
    },
    dataTime1(old, value) {
      // Se a data inicial for maior que a data atual
      if (this.dataInit > moment(new Date()).format('YYYY-MM-DD')) {
        this.dataTime1 = value
      }
      // Se o valor for um horário válido
      else if (value && moment(value, 'HH:mm', true).isValid()) {
        this.dataTime1 = value
      }
      // Caso contrário, aplica a verificação adicional
      else {
        if (value > old) {
          this.dataTime1 = moment().format('HH:mm')
        }
      }
    },

    dataTime2(value) {
      if (!value || value === '00:00') {
        this.valid4 = true
      } else {
        this.valid4 = false
      }
    },
    async clinic() {
      if (this.user.idPerfil === 2 || this.user.idPerfil === 3) {
        this.doctors = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctor = this.doctors[0]
      }
    },
    allDaysUtils(value) {
      if (value === 1) {
        this.listWeek.forEach((item) => {
          if (item.dia !== 'Sabado' && item.dia !== 'Domingo') {
            item.value = 1
          }
        })
      } else {
        this.listWeek.forEach((item) => {
          if (item.dia !== 'Sabado' && item.dia !== 'Domingo') {
            item.value = 0
          }
        })
      }
    },
    allOptions(value) {
      if (value === 1) {
        this.listStatus.forEach((item) => (item.value = 1))
      } else {
        this.listStatus.forEach((item) => (item.value = 0))
      }
    },
    dataInit(newValue) {
      if (newValue === '' || newValue === null) {
        this.valid1 = true
      } else {
        this.valid1 = false
        this.setFinalyofMonth(newValue)
      }
    },
    dataEnd(newValue) {
      if (newValue === '' || newValue === null) {
        this.valid3 = true
      } else {
        this.valid3 = false
      }
    },

    generation(newValue) {
      if (newValue === 1) {
        this.dataList.forEach((item) => {
          item.geraReceita = 1
        })
      } else {
        this.dataList.forEach((item) => {
          item.geraReceita = 0
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  methods: {
    ...mapActions({
      getUsersForClinics: 'Finance/getUsersForClinics'
    }),
    checkDataList() {
      if (this.dataList.length === 0 || this.dataList === null) {
        return true
      } else {
        if (!this.dataList.some((item) => item.check === 1)) {
          return true
        } else {
          return false
        }
      }
    },
    isDesativateAll(item) {
      if (item.dia !== 'Sabado' && item.dia !== 'Domingo') {
        if (item.value === 0) {
          this.allDaysUtils = 0
          this.isDisabledButton()
        } else {
          this.isDisabledButton()
        }
      }
    },
    isDisabledButton() {
      if (
        this.listWeek.some((objeto) => objeto.value === 1) &&
        this.listStatus.some((objeto) => objeto.value === 1)
      ) {
        this.isDisabled = false
      } else {
        this.isDisabled = true
      }
    },
    firstDayOfMonth() {
      const date = new Date()
      return moment(date).format('YYYY-MM-DD')
    },
    getFinalyofMonth() {
      return moment(this.dataInit).add(1, 'month').format('YYYY-MM-DD')
    },
    setFinalyofMonth(date) {
      this.dataEnd = moment(date).add(1, 'month').format('YYYY-MM-DD')
    },
    formartDateInclude(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    close() {
      this.$emit('closeModal')
    },
    handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    isMobile() {
      const isMobileUserAgent =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      if (isMobileUserAgent) {
        return true
      } else {
        return false
      }
    },
    checkDayOfWeek(event, item) {
      const dayOfWeek = moment(event.target.value).format('dddd')
      item.diaSemana = this.translateDayOfWeek(dayOfWeek)
    },
    translateDayOfWeek(dayOfWeek) {
      switch (dayOfWeek) {
        case 'Monday':
          return 'SEGUNDA-FEIRA'
        case 'Tuesday':
          return 'TERÇA-FEIRA'
        case 'Wednesday':
          return 'QUARTA-FEIRA'
        case 'Thursday':
          return 'QUINTA-FEIRA'
        case 'Friday':
          return 'SEXTA-FEIRA'
        case 'Saturday':
          return 'SÁBADO'
        case 'Sunday':
          return 'DOMINGO'
        default:
          return ''
      }
    },
    formatDateTime(date, time) {
      const dateTimeString = `${date}T${time}:00.000Z`
      const formattedDate = moment.utc(dateTimeString).format()
      return formattedDate
    },
    updateMaxDate() {
      if (this.dataInit) {
        const start = new Date(this.dataInit)
        start.setMonth(start.getMonth() + 3)
        this.maxDate = start.toISOString().split('T')[0]
      } else {
        this.maxDate = null
      }
    },
    isValid() {
      if (
        this.valid1 === true ||
        this.valid2 === true ||
        this.valid3 === true ||
        this.valid4 === true
      ) {
        return true
      } else {
        return false
      }
    },
    async getData() {
      if (this.isValid()) {
        this.$toast.error('Verifique os campos obrigatórios!')
      } else {
        this.isLoading = true
        const listaDiaSemana = []
        const listaStatus = []

        this.listWeek.forEach((item) => {
          if (item.value === 1) {
            listaDiaSemana.push(item.id)
          }
        })
        this.listStatus.forEach((item) => {
          if (item.value === 1) {
            listaStatus.push(item.id)
          }
        })

        try {
          const response = await API.post(
            '/api/Agenda/LocalizaHorarioAgendaBloco',
            {
              appName: 'DoctorPep',
              appVersion: '10.3.3',
              object: {
                listaDiaSemana: listaDiaSemana,
                listaStatus: listaStatus,
                id_usuario: this.doctor.idUsuario,
                id_clinica: this.clinic.idClinica,
                dt_inicio: this.formatDateTime(this.dataInit, this.dataTime1),
                dt_fim: this.formatDateTime(this.dataEnd, this.dataTime2)
              }
            }
          )
          this.dataList = response.data.object
          this.dataList.forEach((item) => {
            item.check = 1
          })
          this.allList = 1
        } catch (err) {
          this.$toast.error('Desculpe, tente atualizar em alguns minutos!!')
        } finally {
          this.isLoading = false
        }
      }
    },
    async getExecutedRecorrencia() {
      this.loading = true
      const list = this.dataList.map((item) => {
        const newItem = { ...item }
        delete newItem.check
        return newItem
      })

      try {
        await API.post('api/Agenda/ExexuctarAlteraçãoBlocoAsync', {
          appName: 'DoctorPep',
          appVersion: '10.4.3',
          object: {
            id_status_futuro: this.status.id,
            id_usuario_atualizacao: this.doctor.idUsuario,
            listaResultadoEmBloco: list
          }
        })
        this.loading = false
        this.dataList = []
        this.close()
        this.$toast.success('Seu agendamento em bloco foi salvo com sucesso')
      } catch (err) {
        this.loading = false
        this.dataList = []
        this.close()
        this.$toast.error('Desculpe, tente executar em alguns minutos!!')
      }
    },
    formatData(data) {
      return moment(data).format('YYYY-MM-DD')
    },
    formatHr(data) {
      return moment(data).format('HH:mm')
    },
    removeItem(index) {
      this.dataList.splice(index, 1)
    },
    async initComponent() {
      this.clinics = this.user.clinica_usuario
      this.clinic = this.user.clinica_usuario[0]
      if (
        this.user.idPerfil === 1 ||
        this.user.idPerfil === 4 ||
        this.user.idPerfil === 5
      ) {
        this.doctor = this.user.clinica_usuario[0]
        this.doctors = [this.user.clinica_usuario[0]]
      }
      if (this.user.idPerfil === 2 || this.user.idPerfil === 3) {
        this.doctors = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctor = this.doctors[0]
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress)
  }
}
</script>
<style scoped>
.v-select {
  width: 260px;
}
.container-rec {
  font-size: 0.8rem;
  padding: 6px;
  width: 100%;
  color: #181818;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.data-origem {
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 36px;
  padding-bottom: 12px;
  border-bottom: solid 1px #9b9a9a;
}

.box-period {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.box-period button {
  margin-top: 30px;
  font-size: 0.8rem;
  padding: 6px 12px;
  color: white;
  background-color: #089bab;
  border-radius: 10px;
  border: none;
}

.box-date {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 4px;
}

.standard {
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-date {
  padding: 6px 12px;
  border-radius: 10px;
  border: 1px solid #9b9a9a;
}

.standard input {
  width: 150px;
  padding: 6px 12px;
  border-radius: 10px;
  border: 1px solid #9b9a9a;
}

.table-container {
  width: 100%;
  height: 30vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

td {
  font-size: 0.7rem;
}

.table-header:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

th {
  font-weight: 400 !important;
  background-color: #089bab;
  position: sticky;
  top: 0px;
  z-index: 1;
  max-height: 35px !important;
  height: 19px !important;
  padding: 8px !important;
  border: none !important;
}

th:nth-child(1) {
  width: 50px;
  white-space: nowrap;
}

th:nth-child(2) {
  width: 14%;
}

th:nth-child(3) {
  width: auto;
}

th:nth-child(4) {
  width: auto;
}

th:nth-child(5) {
  width: auto;
}

th:nth-child(6) {
  justify-content: end;
  width: 15%;
}

.button-rec {
  display: flex;
  gap: 4px;
  margin-top: 20px;
  font-size: 0.9rem;
  font-weight: 700;
  align-items: center;
  padding: 6px 12px;
  color: white;
  background-color: #025c66;
  border-radius: 10px;
  border: none;
}
.button-remove {
  font-size: 0.8rem;
  padding: 6px 12px;
  color: red;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid red;
}

.box-list-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.list-card {
  padding: 10px;
  background-color: #fcfeff;
  border: 1px solid rgb(184, 180, 180);
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}
.box-info-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.box-days {
  width: 100%;
  flex-wrap: wrap;
  font-size: 0.7rem;
  display: flex;
  gap: 30px;
  align-items: center;
}

.list-days {
  flex-wrap: wrap;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  gap: 14px;
}

.box-search {
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.box-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.button-search {
  margin-top: 10px;
  font-size: 0.9rem;
  padding: 6px 12px;
  color: white;
  background-color: #089bab;
  border-radius: 10px;
  border: none;
}

.button-search-deactivate {
  margin-top: 10px;
  font-size: 0.9rem;
  padding: 6px 12px;
  color: rgb(228, 225, 225);
  background-color: #025c66;
  border-radius: 10px;
  border: none;
}
.box-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}
</style>
