<template>
  <div>
    <user-preferences-modal />
    <digital-assign-modal
      v-if="digitalAssignIsVisible"
      :userId="user.id"
      @close="changeAssignModalVisibility(false)"
    />
    <terms-of-use-modal
      v-if="termsOfUseIsVisible"
      :alreadyAccepted="true"
      @close="termsOfUseIsVisible = false"
      @confirm="continueLogin"
    />
    <div id="show-overlay"></div>
    <Loader />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1
        :items="verticalMenuRoutes"
        :horizontal="horizontal"
        :logo="logo"
        @toggle="sidebarMini"
        :sidebarFocused="sidebarFocused"
        @click="sidebarFocused = true"
      />
      <div
        id="content-page"
        class="content-page"
        :class="horizontal ? 'ml-0' : ''"
        @click="sidebarFocused = false"
      >
        <!-- TOP Nav Bar -->
        <NavBarStyle1
          title="Dashboard"
          :homeURL="{ name: 'dashboard1.home' }"
          @toggle="sidebarMini"
          :logo="logo"
          :horizontal="horizontal"
          :items="horizontalMenu"
        >
          <template slot="responsiveRight">
            <ul
              class="navbar-nav ml-auto navbar-list"
              style="align-items: center"
            >
              <li>
                <div
                  id="box-assing"
                  class="custom-control custom-switch custom-switch-lg"
                  v-if="![2, 3].includes(user.idPerfil)"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch1"
                    v-on:change="onDigitalAssignSwitchChanged"
                    :checked="digitalAssignSwitchState"
                  />
                  <label class="custom-control-label" for="customSwitch1">
                    Assinatura Digital
                  </label>
                </div>
              </li>
              <li class="nav-item iq-full-screen">
                <a href="#" class="iq-waves-effect" id="btnFullscreen"
                  ><i class="ri-fullscreen-line"></i
                ></a>
              </li>
              <li class="nav-item">
                <a href="#" class="search-toggle iq-waves-effect">
                  <i class="ri-notification-3-fill"></i>
                  <span
                    class="bg-danger dots"
                    v-if="haveNewNotifications"
                  ></span>
                </a>
                <div class="iq-sub-dropdown iq-sub-dropdown-medium">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">
                          {{ $t('nav.allNotifications') }}
                          <small class="badge badge-light float-right pt-1">{{
                            notifications.length
                          }}</small>

                          <div
                            class="d-flex align-items-center float-right mr-3"
                          >
                            <b-form-checkbox
                              name="show-concluded"
                              v-model="showConcludedNotifications"
                              :value="1"
                              :unchecked-value="0"
                            >
                            </b-form-checkbox>

                            <span>Exibir concluidas</span>
                          </div>

                          <div class="float-right mr-3">
                            <b-form-select
                              name="show-concluded"
                              v-model="notificationPeriod"
                              :options="notificationPeriods"
                              size="sm"
                              style="font-size: 11px"
                            />
                          </div>
                        </h5>
                      </div>
                      <div style="max-height: 50vh; overflow-y: auto">
                        <a
                          href="#"
                          class="iq-sub-card"
                          v-for="(item, index) in notifications"
                          :key="index"
                        >
                          <div
                            v-if="item.idTipoNotificacaoTarefa === 1"
                            :class="[
                              'media',
                              'align-items-center',
                              item.visualizacaoNotificacao === 1
                                ? 'concluded'
                                : ''
                            ]"
                          >
                            <div class="media-body ml-2">
                              <h5 class="mb-1 mt-1">Encaminhamento</h5>
                              <div
                                class="float-right"
                                @click="onNotificationClick(item)"
                              >
                                <b-icon
                                  @click="
                                    (e) => {
                                      e.stopPropagation()
                                      onNotificationClick(item)
                                    }
                                  "
                                  :icon="
                                    item.visualizacaoNotificacao === 0
                                      ? 'check'
                                      : 'check-all'
                                  "
                                  font-scale="3"
                                  :variant="
                                    item.visualizacaoNotificacao === 0
                                      ? 'secondary'
                                      : 'success'
                                  "
                                />
                              </div>
                              <p class="mb-0">
                                <strong>Paciente: </strong>
                                {{ item.paciente }}
                              </p>
                              <p class="mb-0">
                                <strong>Especialidade: </strong>
                                {{ item.especialidade }}
                              </p>
                              <p class="mb-0">
                                <strong>Profissional: </strong>
                                {{ item.profissional }}
                              </p>
                              <p class="mb-0">
                                <strong>Prioridade: </strong>
                                {{ item.prioridade }}
                              </p>
                            </div>
                          </div>
                          <div
                            v-else
                            class="media-body ml-2"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                            "
                          >
                            <h5 class="mb-1 mt-1">
                              {{ item.descTipoNotificacaoTarefa }}
                            </h5>
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                gap: 10px;
                              "
                            >
                              <b-icon
                                @click="
                                  (e) => {
                                    e.stopPropagation()
                                    onNotificationClick(item)
                                  }
                                "
                                :icon="
                                  item.visualizacaoNotificacao === 0
                                    ? 'check'
                                    : 'check-all'
                                "
                                font-scale="3"
                                :variant="
                                  item.visualizacaoNotificacao === 0
                                    ? 'secondary'
                                    : 'success'
                                "
                              />
                              <button
                                @click="openTasks()"
                                class="button-tarefa"
                              >
                                Ir para tarefas
                              </button>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  id="profile-preference"
                  href="#"
                  @click="
                    openDrawer === false ? openDrawerAbout() : closeDrawer()
                  "
                  class="profile-preference search-toggle iq-waves-effect d-flex align-items-center"
                >
                  <b-icon icon="patch-question-fill" font-scale="1"></b-icon>
                </a>
              </li>
              <li>
                <a
                  id="profile-preference"
                  href="#"
                  @click="
                    openFeedback === false
                      ? openModalFeedback()
                      : closeModalFeedback()
                  "
                  class="profile-preference search-toggle iq-waves-effect d-flex align-items-center"
                >
                  <b-icon icon="hand-thumbs-up-fill" font-scale="1"></b-icon>
                </a>
              </li>
              <!--<li class="nav-item">
                <a href="#" class="search-toggle iq-waves-effect">
                  <i class="ri-mail-open-fill"></i>
                  <span class="bg-primary count-mail"></span>
                </a>
                <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">
                          {{ $t('nav.allMessages')
                          }}<small class="badge badge-light float-right pt-1"
                            >5</small
                          >
                        </h5>
                      </div>
                      <a
                        href="#"
                        class="iq-sub-card"
                        v-for="(item, index) in message"
                        :key="index"
                      >
                        <div class="media align-items-center">
                          <div class="">
                            <img
                              class="avatar-40 rounded"
                              :src="item.image"
                              alt="img"
                            />
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">{{ item.name }}</h6>
                            <small class="float-left font-size-12">{{
                              item.date
                            }}</small>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li>-->
            </ul>
          </template>
          <template slot="right">
            <ul class="navbar-list">
              <li>
                <a
                  id="profile-preference"
                  href="#"
                  class="profile-preference search-toggle iq-waves-effect d-flex align-items-center"
                >
                  <!-- <img
                    :src="userProfile"
                    class="img-fluid rounded mr-3"
                    alt="user"
                  /> -->
                  <div class="caption">
                    <h6 class="mb-0 line-height">
                      {{ user.nomeSocial ? user.nomeSocial : user.nome }}
                    </h6>
                    <span class="font-size-12">{{
                      $t('nav.user.available')
                    }}</span>
                  </div>
                </a>
                <div class="iq-sub-dropdown iq-dropdown iq-sub-dropdown-small">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white line-height">
                          {{ user.nomeSocial ? user.nomeSocial : user.nome }}
                        </h5>
                      </div>
                      <a
                        v-show="acessPass()"
                        href="#"
                        class="iq-sub-card iq-bg-primary-hover"
                        v-if="[1, 2, 3, 4, 5].includes(this.user.idPerfil)"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-file-line"></i>
                          </div>
                          <div
                            class="media-body ml-3"
                            @click="openUserPreferencesModal()"
                          >
                            <h6 class="mb-0">
                              {{ $t('nav.user.profileTitle') }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t('nav.user.profileSub') }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        v-show="acessPass()"
                        href="#"
                        class="iq-sub-card iq-bg-primary-hover"
                        v-if="[1, 2, 3, 4, 5].includes(this.user.idPerfil)"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-success">
                            <img
                              style="height: 20px; width: 20px"
                              src="../assets/images/certify.png"
                              alt="Icone de Certificado"
                            />
                          </div>
                          <div class="media-body ml-3" @click="openModal">
                            <h6 class="mb-0">
                              {{ $t('nav.user.certify') }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t('nav.user.certifySub') }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        href="#"
                        class="iq-sub-card iq-bg-primary-hover"
                        v-if="[1, 2, 3, 4, 5].includes(this.user.idPerfil)"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i
                              class="ri-file-list-fill"
                              style="color: black"
                            ></i>
                          </div>
                          <div class="media-body ml-3" @click="openTasks()">
                            <h6 class="mb-0">
                              {{ $t('nav.user.tarefas') }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t('nav.user.tarefasSub') }}
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        href="#"
                        class="iq-sub-card iq-bg-primary-success-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-success">
                            <i class="ri-profile-line"></i>
                          </div>
                          <router-link to="/register/add-usuario">
                            <div class="media-body ml-3">
                              <h6 class="mb-0">
                                {{ $t('nav.user.profileEditTitle') }}
                              </h6>
                              <p class="mb-0 font-size-12">
                                {{ $t('nav.user.profileEditSub') }}
                              </p>
                            </div>
                          </router-link>
                        </div>
                      </a>
                      <!-- <a
                        href="#"
                        class="iq-sub-card iq-bg-primary-danger-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-danger">
                            <i class="ri-account-box-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">
                              {{ $t('nav.user.accountSettingTitle') }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t('nav.user.accountSettingSub') }}
                            </p>
                          </div>
                        </div>
                      </a> -->

                      <a
                        href="#"
                        class="iq-sub-card iq-bg-primary-success-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div
                            class="media-body ml-3"
                            @click="termsOfUseIsVisible = true"
                          >
                            <h6 class="mb-0">
                              {{ $t('nav.user.termsOfUseTitle') }}
                            </h6>
                            <p class="mb-0 font-size-12">
                              {{ $t('nav.user.termsOfUseSub') }}
                            </p>
                          </div>
                        </div>
                      </a>

                      <div class="d-inline-block w-100 text-center p-3">
                        <a
                          class="iq-bg-danger iq-sign-btn"
                          href="javascript:void(0)"
                          @click="logout"
                          role="button"
                          >{{ $t('nav.user.signout')
                          }}<i class="ri-login-box-line ml-2"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </NavBarStyle1>
        <!-- TOP Nav Bar END -->
        <transition
          name="router-anim"
          :enter-active-class="`animated ${animated.enter}`"
          mode="out-in"
          :leave-active-class="`animated ${animated.exit}`"
        >
          <router-view />
        </transition>
        <!-- <div class="footer">
          <FooterStyle1>
            <template v-slot:left>
              <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
              <li class="list-inline-item"><a href="#">Terms of Use</a></li>
            </template>
            <template v-slot:right>
              Copyright 2020 <a href="#">DoctorPEP</a> All Rights Reserved.
            </template>
          </FooterStyle1>
        </div> -->
      </div>
    </div>
    <ModalTasks v-if="hangleTasks" @closeModal="closesTasks()" />
    <ModalFeedback v-if="openFeedback" @closeFeed="closeModalFeedback()" />
    <DrawerAbout v-if="openDrawer" @close="closeDrawer()" />
    <modalCertify @close="closeModal" v-if="openModalCertify" />
    <vue-draggable-resizable
      :w="320"
      :h="720"
      :x="0"
      :y="0"
      :z="9999"
      v-if="videoIsVisible"
      class-name="video-container"
      @resizing="onVideoResize"
    >
      <video-component
        @close="connected = false"
        :videoHorizontal="videoOrientationIsHorizontal"
      />
    </vue-draggable-resizable>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import modalCertify from '../components/modalCertify.vue'
import Loader from '../components/xray/loader/Loader'
import SideBarStyle1 from '../components/xray/sidebars/SideBarStyle1'
import NavBarStyle1 from '../components/xray/navbars/NavBarStyle1'
import DigitalAssignModal from '../components/DigitalAssignModal.vue'
import VideoComponent from '../components/Video'

import SideBarItems from '../FackApi/json/SideBar'
import HorizontalItems from '../FackApi/json/HorizontalMenu'

import profile from '../assets/images/user/1.jpg'
import loader from '../assets/images/logo.png'

import { xray } from '../config/pluginInit'
import { Users } from '../FackApi/api/chat'
import TermsOfUseModal from '../components/TermsOfUseModal'
import DrawerAbout from '../components/xray/drawer/DrawerAbout.vue'

import UserPreferencesModal from '../components/UserPreferences/UserPreferencesModal.vue'
import ModalTasks from '../components/Tasks/ModalTasks.vue'
import ModalFeedback from '../components/Feedback/ModalFeedback.vue'

export default {
  name: 'Layout1',
  components: {
    ModalTasks,
    DrawerAbout,
    Loader,
    SideBarStyle1,
    NavBarStyle1,
    UserPreferencesModal,
    DigitalAssignModal,
    TermsOfUseModal,
    VideoComponent,
    modalCertify,
    ModalFeedback
  },
  computed: {
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      colors: 'Setting/colorState',
      user: 'Setting/user',
      digitalAssign: 'DigitalAssign/digitalAssignState',
      digitalAssignIsVisible: 'DigitalAssign/digitalAssignModalIsVisible',
      digitalAssignSwitchState: 'DigitalAssign/switchState',
      notifications: 'Notifications/notificationsState',
      videoIsVisible: 'Video/isVideoComponentVisibleState',
      certificate: 'DigitalAssign/certificate'
    }),
    haveNewNotifications() {
      const verify = this.notifications.some((element) => {
        return element.visualizacaoNotificacao === 0
      })

      return verify
    },

    verticalMenuRoutes() {
      const alloweds = []
      SideBarItems.forEach((item) => {
        if (item.allowedProfileIds) {
          if (item.allowedProfileIds.includes(this.user.idPerfil)) {
            alloweds.push(item)
          }
        } else {
          alloweds.push(item)
        }
      })

      return alloweds
    }
  },
  watch: {
    notificationPeriodValues() {
      this.getNotifications()
    },
    showConcludedNotifications() {
      this.getNotifications()
    }
  },
  // sidebarTicket
  data() {
    return {
      hangleTasks: false,
      openDrawer: false,
      openFeedback: false,
      openModalCertify: false,
      // Sidebar Fixa
      sidebarFocused: false,
      //
      videoOrientationIsHorizontal: false,
      notificationPeriods: [
        { text: '7 dias', value: 7 },
        { text: '15 dias', value: 15 },
        { text: '30 dias', value: 30 }
      ],
      notificationPeriod: 7,
      showConcludedNotifications: 0,
      termsOfUseIsVisible: false,
      horizontal: false,
      mini: false,
      darkMode: false,
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      animateClass: [
        { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
        { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
        {
          value: { enter: 'slideInLeft', exit: 'slideOutRight' },
          text: 'Slide'
        },
        {
          value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' },
          text: 'Roll'
        }
      ],
      horizontalMenu: HorizontalItems,
      userProfile: profile,
      logo: loader,
      usersList: Users,
      rtl: false,
      message: [
        {
          image: require('../assets/images/user/01.jpg'),
          name: 'Nik Emma Watson',
          date: '13 jan'
        },
        {
          image: require('../assets/images/user/02.jpg'),
          name: 'Greta Life',
          date: '14 Jun'
        },
        {
          image: require('../assets/images/user/03.jpg'),
          name: 'Barb Ackue',
          date: '16 Aug'
        },
        {
          image: require('../assets/images/user/04.jpg'),
          name: 'Anna Sthesia',
          date: '21 Sept'
        },
        {
          image: require('../assets/images/user/05.jpg'),
          name: 'Bob Frapples',
          date: '29 Sept'
        }
      ]
    }
  },
  mounted() {
    xray.fullscreen()
    xray.index()
  },
  created() {
    this.verifyDigitalAssignStatus()
    this.$store.dispatch({
      type: 'MedicalReports/loadAllModules',
      payload: this.user.id
    })

    this.recoverCertificate({ cpf: this.user.cpf, type: 'qualified' })
  },
  methods: {
    acessPass() {
      if (this.user.idPerfil === 2 || this.user.idPerfil === 3) {
        return false
      } else {
        return true
      }
    },
    openTasks() {
      this.hangleTasks = true
    },
    closesTasks() {
      this.hangleTasks = false
    },
    closeDrawer() {
      this.openDrawer = false
    },
    openDrawerAbout() {
      this.openDrawer = true
    },
    openModalFeedback() {
      this.openFeedback = true
    },
    closeModalFeedback() {
      this.openFeedback = false
    },
    onVideoResize(x, y, width, height) {
      if (width > height && this.videoOrientationIsHorizontal === false) {
        this.videoOrientationIsHorizontal = true
      } else if (width < height && this.videoOrientationIsHorizontal === true) {
        this.videoOrientationIsHorizontal = false
      }
    },
    async getNotifications() {
      const object = {
        idUser: this.user.id,
        getConcludeds: this.showConcludedNotifications,
        period: this.notificationPeriod
      }

      await this.getNotificationsAction(object)
    },
    onNotificationClick(item) {
      const object = {
        id_notificacao: item.idNotificacao,
        idUser: this.user.id
      }
      this.concludeNotification(object)
    },
    onDigitalAssignSwitchChanged() {
      this.changeDigitalAssignSwitchState()

      if (this.digitalAssignSwitchState) {
        this.changeAssignModalVisibility(true)
      }
    },
    async verifyDigitalAssignStatus() {
      const userId = this.user.id
      await this.getDigitalAssignAction(userId)
      if (this.digitalAssign && this.digitalAssignSwitchState) {
        this.changeDigitalAssignSwitchState()
      }
    },
    openUserPreferencesModal() {
      this.$bvModal.show('user-preferences__modal')
    },
    openModal() {
      this.openModalCertify = true
    },
    closeModal() {
      this.openModalCertify = false
    },
    updateRadio() {
      this.horizontal = this.$store.getters['Setting/horizontalMenuState']
      this.mini = this.$store.getters['Setting/miniSidebarState']
    },
    sidebarHorizontal() {
      this.$store.dispatch('Setting/horizontalMenuAction')
      this.updateRadio()
    },
    sidebarMini() {
      xray.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
      this.updateRadio()
    },
    rtlChange() {
      if (this.rtl) {
        this.rtlRemove()
      } else {
        this.rtlAdd()
      }
    },
    changeColor(code) {
      document.documentElement.style.setProperty('--iq-primary', code.primary)
      document.documentElement.style.setProperty(
        '--iq-primary-light',
        code.primaryLight
      )
      if (this.darkMode) {
        document.documentElement.style.setProperty(
          '--iq-bg-dark-color',
          code.bodyBgDark
        )
      } else {
        document.documentElement.style.setProperty(
          '--iq-bg-light-color',
          code.bodyBgLight
        )
      }
    },
    reset() {
      this.changeColor({
        primary: '#827af3',
        primaryLight: '#b47af3',
        bodyBgLight: '#efeefd',
        bodyBgDark: '#1d203f'
      })
      this.animated = { enter: 'zoomIn', exit: 'zoomOut' }
      this.light()
    },
    logout() {
      this.logoutAction()
      this.$store.commit({
        type: 'DigitalAssign/changeSwichState'
      })
      this.$router.push({ name: 'auth1.sign-in' })
    },
    langChange(lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
      if (lang.value === 'ar') {
        this.rtlAdd(lang)
      } else {
        this.rtlRemove(lang)
      }
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction',
      logoutAction: 'Setting/logoutUserAction',
      getDigitalAssignAction: 'DigitalAssign/getAssignStatusAction',
      changeAssignModalVisibility:
        'DigitalAssign/changeAssignModalVisibilityAction',
      changeDigitalAssignSwitchState: 'DigitalAssign/changeSwitchStateAction',
      recoverCertificate: 'DigitalAssign/recoverCertificate',
      getNotificationsAction: 'Notifications/getNotificationsAction',
      concludeNotification: 'Notifications/concludeNotificationAction'
    })
  }
}
</script>
<style>
@import url('../assets/css/custom.css');

.footer {
  margin-top: 20px;
}

button {
  border-radius: 10px;
}

.concluded {
  background-color: #a2d9ce2c;
}
.button-tarefa {
  padding: 6px 12px;
  background-color: #aeeef5;
  color: #089dae;
  border: 1px solid #089dae;
}
</style>
<style scoped>
@media (max-width: 450px) {
  #box-assing {
    display: flex;
    align-items: center;
    top: 20px;
  }
}
.video-container {
  position: fixed;
  top: 0;
}
</style>
