<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0 !important;
      align-items: center;
    "
  >
    <user-info-edit-modal
      v-if="Object.keys(patientInfo).length > 0"
      :patient-info="patientInfo"
      :update-patient-info="updatePatientInfo"
    />
    <user-info
      :patient-info="patientInfo"
      :id-medico="requestData.idUsuario"
      :allergiesModule="allergiesModule"
      :problemsModule="problemsModule"
      :showVideoComponent="schedule.idTipoAtendimento === 2"
      :videoIdentification="schedule.identificacaoTeleconsulta"
    />
    <div class="modules-container" v-if="medicalReportType === 1">
      <component
        :key="module.idModulo"
        v-for="module in modules"
        :v-if="
          requestData.identificacao &&
          requestData.idPaciente &&
          requestData.idUsuario
        "
        :is="module.component"
        :component-info="module"
        :handbook-info="handbookInfo"
        :id-prontuario="requestData.identificacao"
        :id-paciente="requestData.idPaciente"
        :id-medico="requestData.idUsuario"
        :patient-info="patientInfo"
      />
    </div>
    <div class="modules-container" style="overflow-y: hidden" v-else>
      <b-tabs
        style="
          width: 100%;
          height: max-content;
          background-color: white;
          border-radius: 16px;
          margin-top: 4px;
        "
        active-nav-item-class="bg-primary text-light"
        nav-class="align-items-center p-0"
        align="left"
        justified
      >
        <div>
          <b-tab
            v-for="module in modules"
            :key="module.idModulo"
            @click="includesModuleIdOnrenderedModules(module.idModulo)"
          >
            <template #title>
              <span
                :class="[
                  'tab-item-text',
                  module.descMeuModulo.length > 18
                    ? 'small-font'
                    : 'medium-font'
                ]"
                >{{ module.descMeuModulo }}</span
              >
            </template>
            <component
              :key="module.idModulo"
              v-if="renderedModules.includes(module.idModulo)"
              :is="module.component"
              :component-info="module"
              :handbook-info="handbookInfo"
              :id-prontuario="requestData.identificacao"
              :id-paciente="requestData.idPaciente"
              :id-medico="requestData.idUsuario"
              :patient-info="patientInfo"
            />
          </b-tab>
        </div>
      </b-tabs>
    </div>
    <ul class="menu__options">
      <li class="menu__option" @click="$bvModal.show('cancel-modal')">
        <button type="button" class="btn mb-3 btn-danger" :disabled="isLoading">
          <i class="fas fa-trash"></i>Cancelar
        </button>
      </li>
      <li class="menu__option">
        <button
          type="button"
          class="btn mb-3 btn-warning"
          @click="continueLater"
        >
          <div
            style="display: flex; align-items: center; gap: 6px"
            v-if="isLoadingVerifyC"
          >
            <span>Continuando</span>
            <b-spinner class="alignCenter" small variant="ligth"></b-spinner>
          </div>
          <div v-else><i class="fas fa-forward"></i>Continuar</div>
        </button>
      </li>
      <li class="menu__option">
        <button
          type="button"
          class="btn mb-3 btn-primary"
          @click="verifyDigitalAssignSwitchState"
        >
          <div
            style="display: flex; align-items: center; gap: 6px"
            v-if="isLoadingVerify"
          >
            <span>Assinando</span>
            <b-spinner class="alignCenter" small variant="ligth"></b-spinner>
          </div>
          <div v-else>
            <i class="fas fa-plus"></i>
            Finalizar
            <b-spinner
              v-if="isLoading === true"
              small
              variant="ligth"
            ></b-spinner>
          </div>
        </button>
      </li>
    </ul>

    <prescription-service
      :token="handbookInfo.tokenMemed"
      :handbook-id="requestData.identificacao"
      :idClinic="handbookInfo.idClinica"
      :patient="patientInfo"
      :primary-color="descriptionsModule.corHexadecimal"
      :memedParams="handbookInfo.parametros_Memed"
      v-if="handbookInfo && requestData && patientInfo && descriptionsModule"
    />
    <div class="timer">
      Tempo de atendimento:
      <a
        :style="`color: ${
          time < 1200 ? '#089bab' : 'orange'
        }; font-family: sans-serif`"
        >{{ timerFormated }}</a
      >
    </div>

    <b-modal
      id="cancel-modal"
      title="Cancelar"
      title-class="text-light"
      header-bg-variant="primary"
      cancel-title="Voltar"
      cancel-variant="danger"
      ok-title="Confirmar"
      size="md"
      @ok="deleteHandBook"
      no-close-on-backdrop
    >
      <h3 class="warning-text">Atenção!</h3>

      <h4 class="warning-text">
        Ao cancelar o atendimento neste prontuário, todas as alterações serão
        desfeitas
      </h4>

      <br />

      <h4 class="warning-text">Esta ação não poderá ser desfeita.</h4>

      <br />
    </b-modal>
  </div>
</template>

<script>
import { mapModuleToComponent } from '../../Utils/mappers'

import Problems from '../../components/MedicalReport/modules/Problems'
import Allergy from '../../components/MedicalReport/modules/Allergy'
import ExamsHistory from '../../components/MedicalReport/modules/ExamsHistory'
import PersonalAndFamiliarHistory from '../../components/MedicalReport/modules/PersonalAndFamiliarHistory'
import Complaints from '../../components/MedicalReport/modules/Complaints'
import PhysicalExam from '../../components/MedicalReport/modules/PhysicalExam'
import Conduct from '../../components/MedicalReport/modules/Conduct'
import FilesAndImages from '../../components/MedicalReport/modules/FilesAndImages'
import Prescriptions from '../../components/MedicalReport/modules/MemedIntegration'
import VitalSigns from '../../components/MedicalReport/modules/VitalSigns'
import Forms from '@/components/MedicalReport/modules/Forms'
import Forwardings from '@/components/MedicalReport/modules/Forwardings'
import ASO from '../../components/MedicalReport/modules/ASO'
import Pregnant from '../../components/MedicalReport/modules/Pregnant'
import Tracking from '../../components/MedicalReport/modules/Tracking'

import UserInfoEditModal from '../../components/MedicalReport/UserInfoEditModal.vue'
import UserInfo from '../../components/MedicalReport/UserInfo/index.vue'

import { mapActions, mapGetters } from 'vuex'

import PrescriptionService from '../../components/MedicalReport/modules/MemedIntegration/components/prescription.vue'

import API from '../../services/index'
import moment from 'moment'

export default {
  components: {
    Problems,
    Allergy,
    ExamsHistory,
    PersonalAndFamiliarHistory,
    Complaints,
    PhysicalExam,
    Conduct,
    FilesAndImages,
    Prescriptions,
    UserInfo,
    UserInfoEditModal,
    VitalSigns,
    Forms,
    Forwardings,
    ASO,
    PrescriptionService,
    Pregnant,
    Tracking
  },
  props: ['idPaciente', 'idUsuario', 'identificacao', 'scheduleId'],
  name: 'MedicalReportPage',
  data() {
    return {
      isLoadingVerifyCan: false,
      isLoadingVerifyC: false,
      isLoadingVerify: false,
      mobile: true,
      time: 0,
      schedule: '',
      handbookInfo: {},
      patientInfo: '',
      isLoading: false,
      requestData: null,
      initialModulesValue: '',
      renderedModules: []
    }
  },

  mounted() {
    this.startTimer()
    this.initialModulesValue = JSON.stringify(this.modules)
    if (this.isMobileDevice()) {
      this.mobile = false
    } else {
      this.mobile = true
    }
    this.renderedModules.push(this.modules[0].idModulo)
  },
  beforeDestroy() {
    this.$store.commit('MedicalReports/changeModulesStateChangedToFalse')
  },

  created() {
    if (this.activeModules.length === 0) {
      this.reloadModules()
    }

    this.requestData = {
      idPaciente: this.decodeParamInfo(window.atob(this.idPaciente)),
      idUsuario: this.decodeParamInfo(window.atob(this.idUsuario)),
      identificacao: this.decodeParamInfo(window.atob(this.identificacao)),
      scheduleId: this.decodeParamInfo(window.atob(this.scheduleId))
    }
  },

  watch: {
    requestData() {
      this.getPatientData()
      this.getSchedule()
    },
    async openedFromMedicalReports(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.tryAssignMedicalReport(this.requestData.identificacao)
        this.saveAllData()
      }
    }
  },

  methods: {
    ...mapActions({
      checkedIsExistCertifyActio: 'Forms/checkedIsExistCertifyAction',
      changeAssignModalVisibility:
        'DigitalAssign/changeAssignModalVisibilityAction',
      verifyAssignExpired: 'DigitalAssign/verifyAssignExpired',
      changeOpenedFromMedicalReports:
        'DigitalAssign/changeOpenedFromMedicalReportsAction',
      assignMedicalReport: 'DigitalAssign/assignMedicalReportAction'
    }),

    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    startTimer() {
      setInterval(() => {
        this.time++
      }, 1000)
    },

    includesModuleIdOnrenderedModules(idModule) {
      if (!this.renderedModules.includes(idModule)) {
        this.renderedModules.push(idModule)
      }
    },

    async verifyDigitalAssignSwitchState() {
      this.isLoading = true
      const value = await this.checkedIsExistCertifyActio()
      setTimeout(() => {
        if (this.digitalAssignSwitchState) {
          if (value) {
            this.isLoadingVerify = true
            this.verifyAssignState()
          } else {
            this.isLoading = true
            this.saveAllData()
            this.$toast.success('Prontuario salvo com sucesso!')
          }
        } else {
          this.isLoading = true
          this.saveAllData()
          this.$toast.success('Prontuario salvo com sucesso!')
        }
      }, 2000)
    },
    async verifyAssignState() {
      if (this.certificate.keyType === 'PSC') {
        const response2 = await this.saveAllDataVerify()
        const response = await this.assignMedicalReport(
          this.requestData.identificacao
        )
        if (response2 === true) {
          this.isLoadingVerify = false
          this.$toast.success('Prontuario salvo com sucesso!')
        }
        if (response === false) {
          this.isLoadingVerify = false
          this.$toast.error(
            'Não foi possivel realizar assinatura, por favor acesse as assinatura pendentes para realizar a assinatura!'
          )
        }
        if (response === true) {
          this.isLoadingVerify = false
          this.$toast.success('Prontuario salvo com sucesso!')
          this.$toast.success('Prontuario assinado com sucesso!')
        }
        this.isLoadingVerify = false
        this.$router.push('/pages/agenda')
      } else {
        if (this.digitalAssign) {
          const expired = await this.verifyAssignExpired()

          if (expired) {
            this.isLoadingVerify = false
            this.changeAssignModalVisibility(true)
            this.changeOpenedFromMedicalReports(true)
            return 0
          }
        }

        this.tryAssignMedicalReport(this.requestData.identificacao)

        this.isLoadingVerify = false
        this.saveAllData()
      }
    },
    toggleExpanded() {
      this.$store.dispatch({
        type: 'MedicalReports/toggleExpandModule',
        payload: this.memedModule
      })
    },

    async tryAssignMedicalReport() {
      try {
        const expired = await this.verifyAssignExpired()

        if (!expired) {
          await this.assignMedicalReport(this.requestData.identificacao)
        } else {
          throw new Error()
        }

        this.$toast.success('Prontuario assinado com sucesso!')
      } catch {
        this.$toast.error('Erro ao assinar prontuario, enviado para pendência')
      }
    },

    updatePatientInfo(data) {
      this.patientInfo = data
    },

    decodeParamInfo(n) {
      const decode = (n / 100) * 40
      return decode
    },

    reloadModules() {
      this.$store
        .dispatch({
          type: 'MedicalReports/loadAllModules',
          payload: this.idUsuario
        })
        .then(() => {
          this.$store.dispatch({
            type: 'MedicalReports/getAllModulesData',
            payload: {
              idProntuario: this.requestData.identificacao
            }
          })
        })
    },
    continueLater() {
      this.isLoadingVerifyC = true
      this.isLoading = true
      this.$store
        .dispatch({
          type: 'MedicalReports/saveToHandBook',
          payload: { idHandBook: this.requestData.identificacao }
        })
        .then(() => {
          this.isLoadingVerifyC = false
          this.isLoading = false
          this.$router.push('/pages/agenda')
        })
        .catch(() => {
          this.isLoadingVerifyC = false
          this.isLoading = false
        })
    },

    async getSchedule() {
      const apiResponse = await API.get(
        `/api/Agenda/ObtemItem/${this.requestData.scheduleId}`
      )

      this.schedule = apiResponse.data.object
    },

    saveAllData() {
      const schedule = this.schedule
      this.$store
        .dispatch({
          type: 'MedicalReports/saveToHandBook',
          payload: { schedule, idHandBook: this.requestData.identificacao }
        })
        .then(async () => {
          this.isLoading = false
          await API.put(`api/Agenda/Editar`, {
            object: {
              ...schedule,
              idStatusAgenda: 4
            }
          })

          if (schedule.idAgendaOrigemTriagem) {
            const scheduleOrigem = await API.get(
              `/api/Agenda/ObtemItem/${schedule.idAgendaOrigemTriagem}`
            )

            await API.put(`api/Agenda/Editar`, {
              object: {
                ...scheduleOrigem.data.object,
                idStatusAgenda: 13
              }
            })
          }
          this.isLoadingVerify = false
          this.isLoading = false
          this.$router.push('/pages/agenda')
        })
        .catch(() => {
          this.isLoadingVerify = false
          this.isLoading = false
        })
    },

    async saveAllDataVerify() {
      const schedule = this.schedule
      await this.$store
        .dispatch({
          type: 'MedicalReports/saveToHandBook',
          payload: { schedule, idHandBook: this.requestData.identificacao }
        })
        .then(async () => {
          this.isLoading = false
          await API.put(`api/Agenda/Editar`, {
            object: {
              ...schedule,
              idStatusAgenda: 4
            }
          })

          if (schedule.idAgendaOrigemTriagem) {
            const scheduleOrigem = await API.get(
              `/api/Agenda/ObtemItem/${schedule.idAgendaOrigemTriagem}`
            )

            await API.put(`api/Agenda/Editar`, {
              object: {
                ...scheduleOrigem.data.object,
                idStatusAgenda: 13
              }
            })
          }
          return true
        })
        .catch(() => {
          return false
        })
      return true
    },

    deleteHandBook() {
      this.isLoading = true
      const schedule = {
        ...this.schedule,
        idUsuarioUltAtualizacao: this.user.id,
        dtUltAtualizacao: new Date().toISOString()
      }
      this.$store
        .dispatch({
          type: 'MedicalReports/deleteHandBook',
          payload: { handbook: this.handbookInfo, schedule }
        })
        .then(() => {
          this.isLoading = false
          this.$router.push('/pages/agenda')
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    createFileReader(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        this.patientInfo.avatar = reader
        return reader.result
      }
    },

    async getPatientData() {
      const patient = await API.get(
        `/api/Paciente/ObtemItem/${this.requestData.idPaciente}`
      )

      const indicators = await API.get(
        `/api/Indicadores/IndicadorAgendaPaciente?id_paciente=${this.requestData.idPaciente}`
      )

      const medicalReport = await API.get(
        `/api/Prontuario/ObtemItem/${this.requestData.identificacao}`
      )

      const { convenioAtendimento, pacienteDesde, ...handbookInfo } =
        medicalReport.data.object

      this.patientInfo = {
        avatar: '',
        ...patient.data.object,
        ...indicators.data.object,
        convenioAtendimento,
        pacienteDesde
      }

      this.handbookInfo = {
        ...handbookInfo,
        convenioAtendimento,
        pacienteDesde
      }

      if (this.patientInfo.descArquivoImagem) {
        API.get(
          `api/Paciente/AwsS3ObtemArquivo?arquivoname=${this.patientInfo.descArquivoImagem}&id_paciente=${this.patientInfo.id}`,
          { responseType: 'blob' },
          {
            headers: {
              Authorization: `Bearer ${window.$cookies.get('token')}`
            }
          }
        ).then((res) => {
          this.createFileReader(res.data)
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      certificate: 'DigitalAssign/certificate',
      digitalAssign: 'DigitalAssign/digitalAssignState',
      MedicalReports: 'MedicalReports',
      activeModules: 'MedicalReports/activeModules',
      allModules: 'MedicalReports/allModules',
      getModuleByIdModule: 'MedicalReports/getModuleByIdModule',
      openedFromMedicalReports: 'DigitalAssign/openedFromMedicalReports',
      digitalAssignSwitchState: 'DigitalAssign/switchState',
      modulesStateChanged: 'MedicalReports/getModulesStateChanged',
      medicalReportType: 'MedicalReports/getMedicalReportType',
      user: 'Setting/user'
    }),

    timerFormated() {
      return moment.utc(this.time * 1000).format('mm:ss')
    },

    descriptionsModule() {
      const modules = this.allModules
      const descriptionsModule = modules.find((module) => module.idModulo === 9)
      return descriptionsModule
    },

    allergiesModule() {
      const modules = this.allModules
      const allergiesModule = modules.find((module) => module.idModulo === 2)
      return allergiesModule
    },

    problemsModule() {
      const modules = this.allModules
      const problemsModule = modules.find((module) => module.idModulo === 1)
      return problemsModule
    },
    // Problema
    modules: {
      get() {
        let allowedModules = [...this.activeModules]
        if (this.mobile === false) {
          const filteredModules = allowedModules.filter(
            (module) =>
              module.idModulo === 5 ||
              module.idModulo === 9 ||
              module.idModulo === 11
          )
          allowedModules = filteredModules
        }
        const components = mapModuleToComponent(allowedModules)
        return components
      },
      set(value) {
        this.$store.commit('MedicalReports/updateModules', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modules-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.module {
  margin: 32px 0;
}
.details {
  display: flex;
  &__name {
    font-size: 14px;

    margin: 0;
    width: 300px;
    color: #fff;
    background-color: #089bab;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;

    padding: 0 10px;
    height: 36px;
    margin-right: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-auto-flow: dense;
}

.form-group {
  width: 100% !important;
}

.menu {
  &__options {
    padding: 0 !important;
    margin: 0 !important;
    align-self: end;
    width: max-content;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 0vh;
    right: 2.5vh;
  }
  &__option {
    margin: 0 2px;
  }
}

.row {
  margin: 16px 0;
  justify-content: space-between !important;
}

.tab-item-text {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  height: 25px;
}

.small-font {
  font-size: 10px;
}

.medium-font {
  font-size: 12px;
}

.timer {
  font-size: 16px;
  position: fixed;
  top: 24px;
  left: 280px;
  z-index: 999;
}

.warning-text {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

@media (max-width: 1022px) {
  .timer {
    font-size: 16px;
    position: fixed;
    top: 24px;
    left: 20px;
    z-index: 999;
  }
}

@media (max-width: 600px) {
  .timer {
    font-size: 12px;
    position: fixed;
    top: 24px;
    left: 20px;
    z-index: 999;
  }
}

@media (max-width: 406px) {
  .timer {
    font-size: 0.8rem;
    position: fixed;
    top: 10px;
    left: 20px;
    z-index: 999;
  }
}
</style>
