import API from '../../services/index'
import sortArray from 'sort-array'
import ProblemsActions from './submodules/problems/actions'
import AllergyActions from './submodules/allergy/actions'
import PersonalAndFamiliarHistoryActions from './submodules/personalHistory/actions'
import PhysicalExamActions from './submodules/physicalExam/actions'
import ComplaintsActions from './submodules/complaints/actions'
import ConductActions from './submodules/conduct/actions'
import VitalSignActions from './submodules/vitalSigns/actions'
import FilesAndImagesActions from './submodules/files&images/actions'
import ExamsHistoryActions from './submodules/examHistory/actions'
import FormsActions from './submodules/forms/actions'
import ForwardingsActions from './submodules/forwardings/actions'
import PrescriptionsActions from './submodules/prescriptions/actions'
import ASO from './submodules/aso/actions'
import Pregnant from './submodules/pregnancy/actions'
import Tracking from './submodules/tracking/actions'
import { parseToHtml } from '../../Utils/parsers'

const modulesThatNeedTransformation = {
  5: 'descQueixa',
  6: 'descExameFisico',
  4: 'descHistPessoalFamiliar',
  8: 'descConduta',
  16: 'descMonitoramento'
}

const getApiModules = async (moduleId, modules, moduleToGetData) => {
  const moduleIndex = modules.findIndex(
    (module) => module.idModulo === moduleId
  )

  try {
    const moduleFavorites = (
      await API.get(
        `/api/Favorito${moduleToGetData}/ObtemFavoritosPorModulo?id_prontuario_preferencia=${modules[moduleIndex].id}`
      )
    ).data.object

    if (modulesThatNeedTransformation[moduleId]) {
      moduleFavorites.forEach((favorite) => {
        favorite[`${modulesThatNeedTransformation[moduleId]}`] = parseToHtml(
          favorite[`${modulesThatNeedTransformation[moduleId]}`]
        )
      })
    }

    modules[moduleIndex] = {
      ...modules[moduleIndex],
      favorites: moduleId === 7 ? moduleFavorites[0] : [...moduleFavorites]
    }
  } catch (error) {
    modules[moduleIndex] = {
      ...modules[moduleIndex],
      favorites: []
    }
  }
}

const getApiData = async (requestData, moduleName, apiEndPointLastPart) => {
  try {
    const apiResponse = await API.get(
      `/api/Prontuario${moduleName}/Obtem${apiEndPointLastPart}PorPaciente`,
      {
        params: {
          id_paciente: requestData.id_paciente,
          id_medico: requestData.id_medico,
          id_clinica: requestData.id_clinica
        }
      }
    )

    return apiResponse.data.object
  } catch (error) {
    return []
  }
}

export default {
  ...ProblemsActions,
  ...AllergyActions,
  ...PersonalAndFamiliarHistoryActions,
  ...ComplaintsActions,
  ...PhysicalExamActions,
  ...ConductActions,
  ...VitalSignActions,
  ...FilesAndImagesActions,
  ...ExamsHistoryActions,
  ...FormsActions,
  ...ForwardingsActions,
  ...PrescriptionsActions,
  ...ASO,
  ...Pregnant,
  ...Tracking,

  async saveToHandBook(
    { state, dispatch, commit, getters },
    { payload: { idHandBook } }
  ) {
    state.modules.forEach((module) => {
      switch (module.idModulo) {
        case 1: {
          const newData = module.data.filter(
            (item) => item.isNew || item.modified || item.deleted
          )
          newData.forEach((data) => {
            const { isNew, modified, deleted, acao, ...payload } = data

            if (isNew && deleted) {
              return
            }

            if (isNew) {
              const { id, ...newPayload } = payload
              dispatch({ type: 'addProblemsToHandBook', payload: newPayload })
            } else if (deleted) {
              dispatch({ type: 'removeProblemFromHandBook', payload })
            } else if (modified) {
              dispatch({ type: 'editProblemFromHandBook', payload })
            }
          })

          break
        }
        case 2: {
          const newData = module.data.filter(
            (item) => item.isNew || item.modified || item.deleted
          )
          newData.forEach((data) => {
            const { isNew, modified, deleted, acao, ...payload } = data
            if (isNew && deleted) {
              return
            }
            if (isNew) {
              const { id, ...newPayload } = payload
              dispatch({ type: 'addAllergyToHandBook', payload: newPayload })
            } else if (deleted) {
              dispatch({ type: 'removeAllergyToHandBook', payload })
            } else if (modified) {
              dispatch({ type: 'editAllergyFromHandBook', payload })
            }
          })

          break
        }
        case 3: {
          const newData = module.data.filter(
            (item) => item.isNew || item.modified || item.deleted
          )

          newData.forEach((data) => {
            const { isNew, modified, deleted, acao, ...payload } = data
            if (isNew && deleted) {
              return
            }
            if (isNew) {
              const { id, ...newPayload } = payload
              dispatch({
                type: 'addExamHistoryToHandBook',
                payload: { ...newPayload, idProntuario: idHandBook }
              })
            } else if (deleted) {
              dispatch({
                type: 'removeExamHistoryFromHandBook',
                payload
              })
            } else if (modified) {
              dispatch({
                type: 'editExamHistoryToHandBook',
                payload: { ...payload, idProntuario: idHandBook }
              })
            }
          })

          break
        }
        case 4: {
          const userData = state.localPersonalHistoryState
          const dataExists = Object.keys(userData).length > 0
          if (dataExists) {
            dispatch({
              type: 'addPersonalAndFamiliarHistoryToHandBook',
              payload: userData
            }).then(() => {
              commit({
                type: 'removePersonalAndFamiliarHistoryFromState',
                payload: { userData }
              })
            })
          }
          break
        }
        case 5: {
          const userData = state.localComplaintsState
          const dataExists = Object.keys(userData).length > 0
          if (dataExists) {
            dispatch({
              type: 'addComplaintToHandBook',
              payload: userData
            }).then(() => {
              commit({ type: 'removeComplaintFromState' })
            })
          }
          const modifiedData = module.data.filter(
            (complaint) => complaint.modified
          )

          if (modifiedData.length > 0) {
            modifiedData.forEach((data) => {
              delete data.modified
              dispatch({
                type: 'editComplaintFromHandBook',
                payload: data
              }).then(() => {
                commit({ type: 'removeComplaintFromState' })
              })
            })
          }
          break
        }
        case 6: {
          const userData = state.localPhysicalExamsState
          const dataExists = Object.keys(userData).length > 0
          if (dataExists) {
            dispatch({
              type: 'addPhysicalExamToHandBook',
              payload: userData
            }).then(() => {
              commit({ type: 'removePhysicalExamFromState' })
            })
          }
          const modifiedData = module.data.filter((conduct) => conduct.modified)

          if (modifiedData.length > 0) {
            modifiedData.forEach((data) => {
              delete data.modified
              dispatch({
                type: 'editPhysicalExamFromHandBook',
                payload: data
              }).then(() => {
                commit({ type: 'removePhysicalExamFromState' })
              })
            })
          }
          break
        }

        case 7: {
          const currentVitalSign = getters.currentVitalSign(idHandBook)
          const shouldAdd = currentVitalSign.isNew

          if (shouldAdd) {
            delete currentVitalSign.isNew
            const requestObject = {
              idProntuario: idHandBook,
              sistolica: currentVitalSign.sistolica || null,
              diastolica: currentVitalSign.diastolica || null,
              peso: currentVitalSign.peso || null,
              altura: currentVitalSign.altura || null,
              imc: currentVitalSign.imc || null,
              pulso: currentVitalSign.pulso || null,
              temperatura: currentVitalSign.temperatura || null,
              saturacao: currentVitalSign.saturacao || null,
              quadril: currentVitalSign.quadril || null,
              panturilha: currentVitalSign.panturilha || null,
              abdominal: currentVitalSign.abdominal || null,
              braco: currentVitalSign.braco || null,
              dtOcorrencia: new Date().toLocaleDateString('en-EN'),
              dtInclusao: new Date().toLocaleDateString('en-EN'),
              ativoAbdominal: currentVitalSign.abdominal ? 'S' : 'N',
              ativoBraco: currentVitalSign.braco ? 'S' : 'N',
              ativoImc:
                currentVitalSign.peso && currentVitalSign.altura ? 'S' : 'N',
              ativoPanturilha: currentVitalSign.panturilha ? 'S' : 'N',
              ativoPressao:
                currentVitalSign.sistolica && currentVitalSign.diastolica
                  ? 'S'
                  : 'N',
              ativoPulso: currentVitalSign.pulso ? 'S' : 'N',
              ativoQuadril: currentVitalSign.quadril ? 'S' : 'N',
              ativoTemperatura: currentVitalSign.temperatura ? 'S' : 'N',
              ativoSaturacao: currentVitalSign.saturacao ? 'S' : 'N',
              idUnidadeAbdominal:
                module.favorites.dadosMedidasAbdominal.idUnidade,
              idUnidadeBraco: module.favorites.dadosMedidasBraco.idUnidade,
              idUnidadePanturilha:
                module.favorites.dadosMedidasPanturilha.idUnidade,
              idUnidadePressao: null,
              idUnidadeImc: null,
              idUnidadePulso: module.favorites.dadosMedidasPulso.idUnidade,
              idUnidadeQuadril: module.favorites.dadosMedidasQuadril.idUnidade,
              idUnidadeTemperatura:
                module.favorites.dadosMedidasTemperatura.idUnidade,
              idUnidadeSaturacao:
                module.favorites.dadosMedidasSaturacao.idUnidade
            }

            dispatch({
              type: 'addVitalSignToHandBook',
              payload: requestObject
            })
          }

          const shouldEdit = module.data.filter(
            (vitalSign) => vitalSign.modified
          )

          if (shouldEdit.length > 0) {
            shouldEdit.forEach((vitalSign) => {
              const { modified, ...requestObject } = vitalSign
              dispatch({
                type: 'editVitalSignToHandBook',
                payload: requestObject
              })
            })
          }

          break
        }

        case 8: {
          const userData = state.localConductsState
          const dataExists = Object.keys(userData).length > 0
          if (dataExists) {
            dispatch({
              type: 'addConductToHandBook',
              payload: userData
            }).then(() => {
              commit({ type: 'removeConductFromState' })
            })
          }
          const modifiedData = module.data.filter((conduct) => conduct.modified)

          if (modifiedData.length > 0) {
            modifiedData.forEach((data) => {
              delete data.modified
              dispatch({
                type: 'editConductFromHandBook',
                payload: data
              }).then(() => {
                commit({ type: 'removeConductFromState' })
              })
            })
          }

          break
        }

        case 9: {
          const newData = module.data.filter(
            (item) => item.isNew || item.modified || item.deleted
          )
          newData.forEach((data) => {
            const { isNew, modified, deleted, acao, ...payload } = data
            if (isNew && deleted) {
              return
            }

            if (deleted) {
              dispatch({ type: 'removePrescriptionFromHandbook', payload })
            } else if (modified) {
              dispatch({ type: 'editPrescriptionFromHandbook', payload })
            }
          })

          break
        }

        case 11: {
          const isThereData = Object.keys(state.localFilesState).length > 0
          if (isThereData) {
            dispatch({
              type: 'sendFiles',
              payload: state.localFilesState
            })
          }
          break
        }

        case 12: {
          const newData = module.data.filter(
            (item) => item.isNew || item.modified || item.deleted
          )
          newData.forEach((data) => {
            const { isNew, modified, deleted, acao, ...payload } = data
            if (isNew && deleted) {
              return
            }
            if (isNew) {
              const { id, ...newPayload } = payload
              dispatch({ type: 'addFormToHandBook', payload: newPayload })
            } else if (deleted) {
              dispatch({ type: 'removeFormFromHandBook', payload })
            } else if (modified) {
              dispatch({ type: 'editFormFromHandBook', payload })
            }
          })

          break
        }

        case 13: {
          const newData = module.data.filter(
            (item) => item.isNew || item.modified || item.deleted
          )
          newData.forEach((data) => {
            const { isNew, modified, deleted, acao, ...payload } = data
            if (isNew && deleted) {
              return
            }
            if (isNew) {
              const { id, ...newPayload } = payload
              dispatch({ type: 'addForwardingToHandBook', payload: newPayload })
            } else if (deleted) {
              dispatch({ type: 'removeForwardingFromHandBook', payload })
            } else if (modified) {
              dispatch({ type: 'editForwardingFromHandBook', payload })
            }
          })

          break
        }

        case 14: {
          const newData = module.data.filter(
            (item) => item.isNew || item.modified || item.deleted
          )
          newData.forEach((data) => {
            const { isNew, modified, deleted, acao, ...payload } = data
            if (isNew && deleted) {
              return
            }
            if (isNew) {
              const { id, ...newPayload } = payload
              dispatch({ type: 'addASOToHandBook', payload: newPayload })
            } else if (deleted) {
              const { id } = payload
              dispatch({ type: 'removeASOFromHandBook', payload: id })
            } else if (modified) {
              dispatch({ type: 'editASOFromHandBook', payload })
            }
          })

          break
        }

        case 15: {
          if (state.localCoverSheet) {
            dispatch({
              type: 'addCoverSheetToHandBook',
              payload: state.localCoverSheet
            })
          }

          if (state.localResults.length > 0) {
            state.localResults.forEach((element) => {
              if (element.tempId) {
                delete element.tempId
              }

              const dtOcorrencia = new Date(element.dtOcorrencia).toISOString()
              dispatch({
                type: 'addResultsToHandBook',
                payload: { ...element, dtOcorrencia }
              })
            })

            commit({ type: 'clearResults' })
          }
          if (state.dataVaccines) {
            dispatch({ type: 'saveAllVaccineProntuario' })
            commit({ type: 'clearVaccine' })
          }
          if (state.dataUltrassom) {
            dispatch({ type: 'saveAllUltrassomProntuario' })
            commit({ type: 'clearUltrassom' })
          }
          if (state.dataExamsLaboratorys) {
            dispatch({ type: 'saveAllExamLaboratorys' })
            commit({ type: 'clearExamsLaboratorys' })
          }
          break
        }
        case 16: {
          const userData = state.localTrackingState
          const dataExists = Object.keys(userData).length > 0
          if (dataExists) {
            dispatch({
              type: 'addTrackingToHandBook',
              payload: userData
            }).then(() => {
              commit({
                type: 'removeTrackingFromState',
                payload: { userData }
              })
            })
          }
        }
      }

      module.data = []
    })
  },

  async deleteHandBook({ commit }, { payload }) {
    try {
      const { handbook, schedule } = payload

      await API.delete('/api/Prontuario/Remover', {
        data: {
          object: handbook
        }
      })

      commit({
        type: 'clearResults'
      })

      commit({
        type: 'removeAllAllergiesFromAHandBookState',
        payload: { idProntuario: handbook.id }
      })
      commit({
        type: 'removePersonalAndFamiliarHistoryFromState'
      })
      commit({
        type: 'removeComplaintFromState'
      })
      commit({
        type: 'removePhysicalExamFromState'
      })
      commit({
        type: 'removeConductFromState'
      })

      commit({
        type: 'removeAllVitalSignsFromAHandBookState'
      })

      await API.put(`api/Agenda/Editar`, {
        object: { ...schedule, idStatusAgenda: 2 }
      })
    } catch (e) {}
  },

  async toggleExpandModule({ commit }, { payload }) {
    const isOpen = payload.aberto === 0

    const newModule = {
      ...payload,
      aberto: isOpen ? 1 : 0
    }
    await API.put('/api/ProntuarioPreferencia/Editar', { object: newModule })
    commit({ type: 'toggleExpandModule', payload: newModule })
  },

  async toggleActiveModule({ commit, state }, { payload }) {
    const isActive = payload.ativo === 0

    const newModule = {
      ...payload,
      ativo: isActive ? 1 : 0
    }
    await API.put('/api/ProntuarioPreferencia/Editar', { object: newModule })
    commit({ type: 'toggleActiveModule', payload: newModule })
  },

  async changeModuleBackground({ commit }, { payload }) {
    const {
      component,
      data,
      favorites,
      userPreferenceComponent,
      ...newPayload
    } = payload
    await API.put('/api/ProntuarioPreferencia/Editar', { object: newPayload })
    commit({
      type: 'changeModuleBackground',
      payload: {
        component,
        data,
        favorites,
        userPreferenceComponent,
        ...newPayload
      }
    })
  },

  async changeModuleName({ commit }, { payload }) {
    await API.put('/api/ProntuarioPreferencia/Editar', {
      object: payload
    })

    commit({
      type: 'changeModuleName',
      payload
    })
  },

  async loadAllModules({ commit }, { payload }) {
    const responseModules = await API.get(
      `/api/ProntuarioPreferencia/ObtemListaPorMedico?id_medico=${payload}`
    )
    const modules = responseModules.data.object
    const promises = [
      getApiModules(1, modules, 'Problema'),
      getApiModules(2, modules, 'Alergia'),
      getApiModules(3, modules, 'HistoricoExame'),
      getApiModules(4, modules, 'HistPessoalFamiliar'),
      getApiModules(5, modules, 'Queixa'),
      getApiModules(6, modules, 'ExameFisico'),
      getApiModules(7, modules, 'SinalVital'),
      getApiModules(8, modules, 'Conduta'),
      getApiModules(11, modules, 'ArquivoImagem'),
      getApiModules(12, modules, 'FormularioAvaliacao'),
      getApiModules(13, modules, 'EncaminhamentoContrarreferencia'),
      getApiModules(14, modules, 'Aso'),
      getApiModules(15, modules, 'PreNatal'),
      getApiModules(16, modules, 'Monitoramento')
    ]

    await Promise.allSettled(promises)

    const currentWorkingModules = modules.filter(
      (module) => module.favorites || [9, 7, 3].includes(module.idModulo)
    )

    sortArray(currentWorkingModules, {
      by: 'sequencia',
      order: 'asc'
    })

    commit('loadAllModules', currentWorkingModules)
  },

  async loadProblemsModuleData({ commit }, { payload }) {
    const problems = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Problema',
      'Problemas'
    )
    commit({
      type: 'loadAllProblems',
      payload: { problems, handbookId: payload.idProntuario }
    })
  },

  async loadAllergiesModuleData({ commit }, { payload }) {
    const allergies = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Alergia',
      'Alergia'
    )

    commit({
      type: 'loadAllAllergies',
      payload: { allergies, handbookId: payload.idProntuario }
    })
  },

  async loadPersonalHistoryModuleData({ commit }, { payload }) {
    const personalHistory = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'HistPessoalFamiliar',
      'Historico'
    )

    commit({
      type: 'loadHistory',
      payload: personalHistory
    })
  },

  async loadExamsHistoryModuleData({ commit }, { payload }) {
    const examsHistory = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },

      'HistoricoExame',
      'Exames'
    )
    commit({
      type: 'loadExamsHistory',
      payload: { examsHistory, handbookId: payload.idProntuario }
    })
  },

  async loadComplaintsModuleData({ commit }, { payload }) {
    const complaints = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Queixa',
      'Queixa'
    )

    commit({
      type: 'loadComplaints',
      payload: complaints
    })
  },

  async loadPhysicalExamsModuleData({ commit }, { payload }) {
    const physicalExams = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'ExameFisico',
      'Exame'
    )
    commit({
      type: 'loadPhysicalExams',
      payload: physicalExams
    })
  },

  async loadVitalSignsModuleData({ commit }, { payload }) {
    const vitalSigns = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'SinalVital',
      'SinalVital'
    )
    commit({
      type: 'loadVitalSigns',
      payload: { vitalSigns, handbookId: payload.idProntuario }
    })
  },

  async loadConductsModuleData({ commit }, { payload }) {
    const conducts = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Conduta',
      'Conduta'
    )
    commit({
      type: 'loadConducts',
      payload: conducts
    })
  },

  async loadFormsModuleData({ commit }, { payload }) {
    const forms = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'FormularioAvaliacao',
      'FormularioAvaliacao'
    )

    commit({
      type: 'loadForms',
      payload: forms
    })
  },

  async loadForwardingsModuleData({ commit }, { payload }) {
    const forwards = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'EncaminhamentoContrarreferencia',
      'Encaminhamento'
    )

    commit({
      type: 'loadForwardings',
      payload: forwards
    })
  },

  async loadPrescriptionsModuleData({ commit }, { payload }) {
    const prescriptions = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Medicamento',
      'Medicamento'
    )

    commit({
      type: 'loadPrescriptions',
      payload: prescriptions
    })
  },

  async loadASOModuleData({ commit }, { payload }) {
    const asos = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Aso',
      'Aso'
    )

    commit({
      type: 'loadASOs',
      payload: asos
    })
  },

  async loadPregnantModuleData({ commit }, { payload }) {
    const pregnancy = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Pregnant',
      'Pregnant'
    )

    commit({
      type: 'loadPregnancy',
      payload: pregnancy
    })
  },

  async loadTrackingModuleData({ commit }, { payload }) {
    const tracking = await getApiData(
      {
        id_paciente: payload.idPaciente,
        id_medico: payload.idUsuario,
        id_clinica: payload.idClinica
      },
      'Monitoramento',
      'Historico'
    )

    commit({
      type: 'loadTracking',
      payload: tracking
    })
  },

  async getAllModulesData({ commit, dispatch }, { payload: { idProntuario } }) {
    const handBookInformationResponse = await API.get(
      `/api/Prontuario/ObtemItem/${idProntuario}`
    )

    const {
      object: { idPaciente, idUsuario, idClinica }
    } = handBookInformationResponse.data

    const getModuleDataFastFunctions = [
      'loadProblemsModuleData',
      'loadAllergiesModuleData',
      'loadPersonalHistoryModuleData',
      'loadExamsHistoryModuleData',
      'loadComplaintsModuleData',
      'loadPhysicalExamsModuleData',
      'loadConductsModuleData',
      'loadFormsModuleData',
      'loadForwardingsModuleData',
      'loadASOModuleData',
      'loadTrackingModuleData'
    ]

    const getModuleDataSlowFunctions = [
      'loadVitalSignsModuleData',
      'loadPrescriptionsModuleData'
    ]

    const fastPromises = [...getModuleDataFastFunctions].map((element) => {
      return dispatch({
        type: element,
        payload: { idProntuario, idUsuario, idPaciente, idClinica }
      })
    })

    const slowPromises = [...getModuleDataSlowFunctions].map((element) => {
      dispatch({
        type: element,
        payload: { idProntuario, idUsuario, idPaciente, idClinica }
      })
    })

    /* await */ Promise.allSettled(slowPromises)
    await Promise.allSettled(fastPromises)
    // getApiData(
    //   {
    //     id_paciente: idPaciente,
    //     id_medico: idUsuario
    //   },
    //   'ArquivoImagem',
    //   'ArquivoImagem'
    // ).then((result) => {
    //   commit({
    //     type: 'loadFileImage',
    //     payload: { id: 11, fileImage: result }
    //   })
    // })
  },

  swapUp({ state, commit }, { payload: moduleToMoveUp }) {
    const stateModulesCopy = [...state.modules]
    if (
      moduleToMoveUp.id === stateModulesCopy[stateModulesCopy.length - 1].id
    ) {
      return
    }

    for (let index = 0; index <= stateModulesCopy.length - 1; index++) {
      if (stateModulesCopy[index].id === moduleToMoveUp.id) {
        const aux = stateModulesCopy[index]
        stateModulesCopy[index] = stateModulesCopy[index + 1]
        stateModulesCopy[index] = {
          ...stateModulesCopy[index],
          modified: true,
          sequencia: index
        }
        stateModulesCopy[index + 1] = aux
        stateModulesCopy[index + 1] = {
          ...stateModulesCopy[index + 1],
          modified: true,
          sequencia: index + 1
        }

        break
      }
    }

    stateModulesCopy.forEach(async (module) => {
      if (module.modified) {
        delete module.modified
        await API.put('/api/ProntuarioPreferencia/Editar', { object: module })
      }
    })

    commit({ type: 'updateModules', payload: [...stateModulesCopy] })
  },

  async swapDown({ state, commit }, { payload: moduleToMoveDown }) {
    const stateModulesCopy = [...state.modules]

    if (moduleToMoveDown.id === stateModulesCopy[0].id) {
      return
    }
    for (let index = 0; index <= stateModulesCopy.length - 1; index++) {
      if (stateModulesCopy[index].id === moduleToMoveDown.id) {
        const aux = stateModulesCopy[index]
        stateModulesCopy[index] = stateModulesCopy[index - 1]
        stateModulesCopy[index] = {
          ...stateModulesCopy[index],
          modified: true,
          sequencia: index
        }
        stateModulesCopy[index - 1] = aux
        stateModulesCopy[index - 1] = {
          ...stateModulesCopy[index - 1],
          modified: true,
          sequencia: index - 1
        }
        break
      }
    }

    stateModulesCopy.forEach(async (module) => {
      if (module.modified) {
        delete module.modified
        await API.put('/api/ProntuarioPreferencia/Editar', { object: module })
      }
    })

    commit({ type: 'updateModules', payload: [...stateModulesCopy] })
  },

  async getMedicalReportTypePreferenceAction(context, payload) {
    try {
      const response = await API.get(
        '/api/ProntuarioModeloUsuario/ObtemModeloPorUsuario',
        { params: { id_usuario: payload.idUser } }
      )

      context.commit('changeMedicalReportType', response.data.object[0])
    } catch {
      return false
    }
  },

  async changeMedicalReportTypePreferenceAction(context, payload) {
    const medicalReportType = {
      ...context.state.medicalReportType,
      idModelo: payload
    }

    try {
      await API.put('/api/ProntuarioModeloUsuario/Editar', {
        object: medicalReportType
      })

      context.commit('changeMedicalReportType', medicalReportType)
    } catch {
      return false
    }
  }
}
