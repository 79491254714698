export const parseToAscii = (content) => {
  let parsedAscii = ''
  for (let index = 0; index < content.length; index++) {
    if (index === content.length - 1) {
      parsedAscii += `${content.charCodeAt(index)}`
    } else {
      parsedAscii += `${content.charCodeAt(index)}|`
    }
  }

  return parsedAscii
}

export const parseToHtml = (content) => {
  const letters = content.split('|').filter((element) => element)
  const parsedHTML = letters
    .map((code) => {
      const charCode = parseInt(code, 10)
      return String.fromCharCode(charCode)
    })
    .join('')

  const formattedHTML = parsedHTML
    .replace('<p></p>', /(\r\n|\r|\n){2,}/g) // Preserva parágrafos
    .replace(/(<br\s*\/?\s*>){1,}/gi, '<br>') // Substitui duas ou mais quebras de linha por uma única
    .replace(/<\/div>/gi, '\n') // Substitui </div> por \n
    .replace(/<[^>]+>/g, '') // Remove todas as outras tags HTML

  // const parsedHTML = String.fromCharCode(...letters)
  return formattedHTML
}
