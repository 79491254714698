<template>
  <div class="painel">
    <span class="labelPainel">Lucro ( Receita x Despesa )</span>
    <div class="comparative">
      <ValidationObserver ref="form">
        <div class="card-standard">
          <div class="header-card">
            <span>Total por Profissional</span>
          </div>
          <hr class="divider" />
          <v-select
            class="v-select"
            v-model="clinicRevenue1"
            :options="clinicsRevenue1"
            label="nmClinica"
            :clearable="false"
          ></v-select>
          <v-select
            class="v-select"
            v-model="situetion"
            :options="listSituetion"
            label="descSituacao"
            :clearable="true"
          ></v-select>
          <div class="card-data">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateInit1"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                v-model="dateEnd1"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                type="date"
              />
              <div class="invalid-feedback">
                <span>Informe a data!</span>
              </div>
            </ValidationProvider>
          </div>
          <hr class="divider" />
          <div class="card-radios">
            <b-form-checkbox
              id="checkbox-one1"
              v-model="statusOne1"
              name="checkbox-one1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Emissão
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-two1"
              v-model="statusTwo1"
              name="checkbox-tow1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Vencimento
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox-there1"
              v-model="statusThere1"
              name="checkbox-there1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Data de Pagamento
            </b-form-checkbox>
          </div>
          <div class="format-selection">
            <span class="format-text">Escolha o formato do relatório: </span>
            <div class="format-buttons">
              <button @click="openGenerator()" class="format-button">
                <img
                  src="../../../../assets/images/finance/pdf.png"
                  alt="pdf"
                />
                <div
                  v-if="loading && loadingType === 'pdf'"
                  class="loading-spinner"
                ></div>
              </button>
              <button @click="gerarPlanilha()" class="format-button">
                <img
                  src="../../../../assets/images/finance/xls.png"
                  alt="excel"
                />
                <div
                  v-if="loading && loadingType === 'xls'"
                  class="loading-spinner"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <pdfGeneratorCompare
      v-if="open"
      @close="closeGenerator"
      :dataRevenue="dataRevenue"
      :dataExpense="dataExpense"
      :date="getDateForPDF()"
      :status="nameSituetion"
      :namedCheckbox="getNamedStatusCheckbox()"
      :clinicDecison="clinicOption"
    />
  </div>
</template>
<script>
import moment from 'moment'
import pdfGeneratorCompare from '../pdfGeneratorCompare.vue'
import { mapActions, mapGetters } from 'vuex'
import * as XLSX from 'xlsx'
export default {
  components: { pdfGeneratorCompare },
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },

      clinicOption: '',

      clinicRevenue1: '',
      clinicsRevenue1: [],

      situetion: '',
      listSituetion: [],

      doctor1: '',
      doctors1: [],

      dateInit1: this.getInitialOfMonth(),
      dateEnd1: this.getFinalyofMonth(),

      statusOne1: 'accepted',
      statusTwo1: 'not_accepted',
      statusThere1: 'not_accepted',

      dataRevenue: [],
      dataExpense: [],
      nameSituetion: '',

      open: false,
      whereDate: 0,
      loading: false,
      loadingType: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    })
  },
  methods: {
    ...mapActions({
      getDataForPDF: 'Finance/getDataForPDF',
      getStatusFinance: 'Finance/getStatusFinance',
      getUsersForClinics: 'Finance/getUsersForClinics',
      getDataForPDFExpenses: 'Finance/getDataForPDFExpenses'
    }),
    closeGenerator() {
      this.open = false
    },
    getFinalyofMonth() {
      const finalDoMes = new Date()
      finalDoMes.setMonth(finalDoMes.getMonth() + 1)
      finalDoMes.setDate(0)
      return moment(finalDoMes).format('YYYY-MM-DD')
    },
    getInitialOfMonth() {
      const inicioDoMes = new Date()
      inicioDoMes.setDate(1)
      return moment(inicioDoMes).format('YYYY-MM-DD')
    },
    getDateForPDF() {
      const date = { dt_inicio: this.dateInit1, dt_final: this.dateEnd1 }
      return date
    },
    getStatusCheckboxOne() {
      if (this.statusOne1 === 'accepted') {
        return 1
      }
      if (this.statusTwo1 === 'accepted') {
        return 2
      }
      if (this.statusThere1 === 'accepted') {
        return 3
      }
    },
    getNamedStatusCheckbox() {
      if (this.statusOne1 === 'accepted') {
        return 'Data de Emissão'
      }
      if (this.statusTwo1 === 'accepted') {
        return 'Data de Vencimento'
      }
      if (this.statusThere1 === 'accepted') {
        return 'Data de Pagamento'
      }
    },
    async openGenerator() {
      this.loading = true
      this.loadingType = 'pdf'
      try {
        const isValid = await this.$refs.form.validate()
        if (!isValid) {
          this.$toast.error('Verifique os campos obrigatórios!')
          return
        }

        const form = {
          dt_inicio: moment(this.dateInit1).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          dt_final: moment(this.dateEnd1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          id_usuario: 0,
          id_clinica: this.clinicRevenue1.idClinica,
          id_status: this.situetion.id,
          verifica_data: this.getStatusCheckboxOne()
        }
        const listTable = await this.getDataForPDFExpenses(form)
        const listTable2 = await this.getDataForPDF(form)
        this.dataExpense = listTable
        this.dataRevenue = listTable2
        this.nameSituetion = this.situetion.descSituacao
        this.whereDate = 1
        this.clinicOption = this.clinicRevenue1
        this.open = true
      } catch (error) {
        this.$toast.error(error)
      } finally {
        this.loading = false
        this.loadingType = ''
      }
    },
    async gerarPlanilha() {
      this.loading = true
      this.loadingType = 'xls'
      try {
        const isValid = await this.$refs.form.validate()
        if (!isValid) {
          this.$toast.error('Verifique os campos obrigatórios!')
          return
        }
        const form = {
          dt_inicio: moment(this.dateInit1).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          dt_final: moment(this.dateEnd1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          id_usuario: 0,
          id_clinica: this.clinicRevenue1.idClinica,
          id_status: this.situetion.id,
          verifica_data: this.getStatusCheckboxOne()
        }

        const listTable = await this.getDataForPDFExpenses(form)
        const listTable2 = await this.getDataForPDF(form)
        this.dataExpense = listTable
        this.dataRevenue = listTable2

        const combinedData = this.combineData()

        const wb = XLSX.utils.book_new()

        const ws = XLSX.utils.aoa_to_sheet(combinedData)
        XLSX.utils.book_append_sheet(wb, ws, 'Despesas_e_Receitas')

        XLSX.writeFile(wb, 'planilha_despesas_e_receitas.xlsx')
      } catch (error) {
        this.$toast.error(error)
      } finally {
        this.loading = false
        this.loadingType = ''
      }
    },

    combineData() {
      const combinedData = []

      combinedData.push(['Planilha de despesas'])
      combinedData.push([
        'recebedor',
        'titulo',
        'vencimento',
        'pagamento',
        'valor',
        'fornecedor',
        'centro de Custo',
        'status'
      ])

      for (let i = 0; i < this.dataExpense.length; i++) {
        combinedData.push([
          this.dataExpense[i].nmResPagador === null
            ? this.dataExpense[i].descClinica
            : this.dataExpense[i].nmResPagador,
          this.dataExpense[i].numeroTitulo,
          this.getDateFormated(this.dataExpense[i].dtVencimento),
          this.getDateFormated(this.dataExpense[i].dtUltimoPagamento),
          this.dataExpense[i].valorTitulo,
          this.dataExpense[i].descFornecedor,
          this.dataExpense[i].descCentroCusto,
          this.dataExpense[i].descStatusFinanceiro
        ])
      }

      combinedData.push([])

      combinedData.push(['Planilha de receitas'])
      combinedData.push([
        'recebedor',
        'tipo',
        'titulo',
        'vencimento',
        'recebimento',
        'valor',
        'saldo',
        'desconto',
        'recebido',
        'tipo_pagamento',
        'situacao'
      ])

      for (let i = 0; i < this.dataRevenue.length; i++) {
        combinedData.push([
          this.dataRevenue[i].nmRecebedor,
          this.dataRevenue[i].descTipoRecebimento,
          this.dataRevenue[i].numeroTitulo,
          this.getDateFormated(this.dataRevenue[i].dtVencimento),
          this.getDateFormated(this.dataRevenue[i].dtRecebimento),
          this.dataRevenue[i].valor,
          this.dataRevenue[i].saldo,
          this.dataRevenue[i].desconto,
          this.dataRevenue[i].valorRecebido,
          this.dataRevenue[i].tipoPagamento,
          this.dataRevenue[i].descStatusFinanceiro
        ])
      }

      return combinedData
    },
    getDateFormated(value) {
      if (value === '' || value === null || value === undefined) {
        return ''
      } else {
        const newadate = moment(value).format('DD/MM/YYYY')
        return newadate
      }
    },
    async initComponent() {
      this.clinicsRevenue1 = this.user.clinica_usuario
      this.clinicRevenue1 = this.user.clinica_usuario[0]
      this.clinicsRevenue2 = this.user.clinica_usuario
      this.clinicRevenue2 = this.user.clinica_usuario[0]
      this.clinicsRevenue3 = this.user.clinica_usuario
      this.clinicRevenue3 = this.user.clinica_usuario[0]
      if (
        this.user.idPerfil === 1 ||
        this.user.idPerfil === 4 ||
        this.user.idPerfil === 5
      ) {
        this.doctor1 = this.user.clinica_usuario[0]
        this.doctors1 = [this.user.clinica_usuario[0]]
        this.doctor2 = this.user.clinica_usuario[0]
        this.doctors2 = [this.user.clinica_usuario[0]]
      }
      if (this.user.idPerfil === 2 || this.user.idPerfil === 3) {
        this.doctors1 = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctors2 = await this.getUsersForClinics({
          clinic: this.user.clinica_usuario[0].idClinica,
          authToken: this.authToken
        })
        this.doctor1 = this.doctors1[0]
        this.doctor2 = this.doctors2[0]
      }
    }
  },
  watch: {
    statusOne1(newValue) {
      if (newValue === 'accepted') {
        this.statusTwo1 = 'not_accepted'
        this.statusThere1 = 'not_accepted'
      }
    },
    statusTwo1(newValue) {
      if (newValue === 'accepted') {
        this.statusOne1 = 'not_accepted'
        this.statusThere1 = 'not_accepted'
      }
    },
    statusThere1(newValue) {
      if (newValue === 'accepted') {
        this.statusOne1 = 'not_accepted'
        this.statusTwo1 = 'not_accepted'
      }
    },
    statusOne2(newValue) {
      if (newValue === 'accepted') {
        this.statusTwo2 = 'not_accepted'
        this.statusThere2 = 'not_accepted'
      }
    },
    statusTwo2(newValue) {
      if (newValue === 'accepted') {
        this.statusOne2 = 'not_accepted'
        this.statusThere2 = 'not_accepted'
      }
    },
    statusThere2(newValue) {
      if (newValue === 'accepted') {
        this.statusOne2 = 'not_accepted'
        this.statusTwo2 = 'not_accepted'
      }
    }
  },
  async mounted() {
    this.initComponent()
    this.listSituetion = await this.getStatusFinance()
    this.situetion = this.listSituetion[0]
  }
}
</script>
<style scoped>
.painel {
  width: 100%;
  padding: 1.2rem;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 70px;
}
.format-selection {
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre o texto e os botões */
}

.format-text {
  color: #000;
}

.format-buttons {
  display: flex;
}

.format-button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.format-button img {
  width: 34px; /* Ajuste o tamanho dos ícones conforme necessário */
  height: auto;
}
.loading-spinner {
  margin-top: 5px; /* Espaço entre o ícone e o spinner */
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.labelPainel {
  font-size: 1.4rem;
  font-weight: 600;
}

.comparative {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
}

.card-standard {
  border-radius: 10px;
  border: 1px #b5bbbf solid;
  background-color: white;
  height: 330px;
  min-width: 400px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 4px;
}

.header-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-card span {
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  color: #b5bbbf;
}
.header-card div {
  display: flex;
  gap: 6px;
  align-items: center;
}

.header-card button {
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
}

.header-card img {
  height: 30px;
  width: 30px;
}

.divider {
  border: none;
  height: 0.5px;
  background-color: #b5bbbf;
  border-radius: 10px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.v-select {
  width: 100%;
}

.card-data {
  display: flex;
  gap: 10px;
  align-items: center;
}
.invalid-feedback {
  font-size: 0.8rem;
}

.card-data input {
  font-size: 0.8rem;
  padding: 6px 6px;
  border: 1px #b5bbbf solid;
  border-radius: 10px;
}

.card-radios {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
</style>
