<template>
  <div>
    <b-row>
      <b-col md="12">
        <span>Filtros:</span>
      </b-col>
      <b-col md="6">
        <label>Médico:</label>
        <v-select
          v-if="isManager"
          :options="doctors"
          label="nmUsuario"
          v-model="selectedDoctor"
          :clearable="false"
        />
        <b-form-input v-else readonly v-model="selectedDoctor.nmUsuario" />
      </b-col>
      <b-col md="6">
        <label>Clinicas:</label>
        <v-select
          v-if="!isManager"
          :options="clinics"
          label="nmClinica"
          v-model="selectedClinic"
          :clearable="false"
        />

        <b-form-input v-else readonly v-model="selectedClinic.nmClinica" />
      </b-col>
    </b-row>
    <b-row style="margin-top: 20px">
      <b-col md="12">
        <b-table-simple hover>
          <b-thead class="table-header">
            <b-tr>
              <b-th> Paciente </b-th>
              <b-th> Periodo </b-th>
              <b-th> Intervalo </b-th>
              <b-th> Dias da semana </b-th>
              <b-th />
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in items" :key="item.id">
              <b-td> {{ item.patientName }} </b-td>
              <b-td
                >{{ handleFormatDate(item.initialDate) }} a
                {{ handleFormatDate(item.finalDate) }}</b-td
              >
              <b-td> {{ formatPeriods(item) }} </b-td>
              <b-td> {{ formatDays(item) }} </b-td>
              <b-td style="display: flex">
                <b-button
                  variant="primary"
                  @click="handleOpenScheduleModal(item)"
                >
                  Agendar
                </b-button>
                <b-button
                  variant="danger"
                  style="margin-left: 8px"
                  @click="handleOpenDeleteModal(item)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>

    <ScheduleModal
      v-if="showScheduleModal"
      :schedule="{}"
      :waitingItemToSchedule="itemToSchedule"
      :doctor="itemToSchedule.idUser"
      :clinica="itemToSchedule.idClinic"
      :date-to-view="null"
      @modalClosed="handleScheduleModalClosed"
      @updateCalendar="removeScheludedItem"
    />

    <b-modal
      id="delete-modal"
      title="Excluir agendamento"
      header-bg-variant="primary"
      title-class="text-light"
      ok-title="Excluir"
      cancel-title="Cancelar"
      cancel-variant="danger"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      @ok="handleDeleteSchedule"
      @close="itemToDelete = ''"
    >
      <h4 style="text-align: center">
        Excluir agendamento da lista de espera?
      </h4>
      <h4 style="text-align: center">Esta ação não poderá ser desfeita.</h4>
    </b-modal>
  </div>
</template>

<script>
import ScheduleModal from '../ToScheduleModal'
import { mapActions, mapGetters } from 'vuex'
import { formatDate } from '../../../Utils/formatters'
export default {
  name: 'WaitingList',
  components: {
    ScheduleModal
  },
  props: ['doctors', 'clinics'],
  data() {
    return {
      selectedDoctor: '',
      selectedClinic: '',
      items: [],
      itemToSchedule: '',
      showScheduleModal: false,
      itemToDelete: ''
    }
  },
  mounted() {
    if (this.doctors.length > 0) {
      this.selectedDoctor = this.doctors[0]
    }
    setTimeout(() => {
      if (this.clinics.length > 0) {
        this.selectedClinic = this.clinics[0]
      }
    }, 100)
  },

  methods: {
    ...mapActions({
      getAwaitingListByDoctorAndClinicAction:
        'WaitingList/getAwaitingListByDoctorAndClinicAction',
      deleteWaitingListItemAction: 'WaitingList/deleteWaitingListItemAction'
    }),
    async getAwaitingListByDoctorAndClinic() {
      try {
        const items = await this.getAwaitingListByDoctorAndClinicAction({
          idDoctor: this.selectedDoctor.idUsuario,
          idClinic: this.selectedClinic.idClinica
        })

        this.items = items
      } catch (e) {
        if (e.response.status === 404) {
          this.$toast.info(
            'Não há agendamentos pendentes para este Médico nesta Clinica'
          )

          this.items = []
        }
      }
    },
    handleFormatDate(date) {
      return formatDate(date)
    },
    handleScheduleModalClosed() {
      this.showScheduleModal = false
      this.$emit('update')
      this.$emit('close')
    },
    handleOpenScheduleModal(item) {
      this.itemToSchedule = item
      this.showScheduleModal = true
    },
    handleOpenDeleteModal(item) {
      this.itemToDelete = item
      this.$bvModal.show('delete-modal')
    },
    async handleDeleteSchedule() {
      await this.deleteWaitingListItemAction(this.itemToDelete.id)
      const index = this.items.findIndex(
        (element) => this.itemToDelete.id === element.id
      )

      this.items.splice(index, 1)
    },
    async removeScheludedItem() {
      this.itemToDelete = this.itemToSchedule
      await this.handleDeleteSchedule()
      this.$emit('close')
    },
    formatPeriods(item) {
      let periods = ''

      item.selectedPeriods.forEach((period, index) => {
        if (index > 0) {
          periods += '/' + period.description
        } else {
          periods += period.description
        }
      })

      return periods
    },

    formatDays(item) {
      let days = ''

      item.selectedDays.forEach((day, index) => {
        if (index > 0) {
          days += '/' + day.description.substring(0, 3).toUpperCase()
        } else {
          days += day.description.substring(0, 3).toUpperCase()
        }
      })

      return days
    }
  },

  watch: {
    selectedDoctor() {
      if (this.selectedClinic) {
        this.getAwaitingListByDoctorAndClinic()
      }
    },
    selectedClinic() {
      if (this.selectedDoctor) {
        this.getAwaitingListByDoctorAndClinic()
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    }),
    isManager() {
      return [2, 3].includes(this.user.idPerfil)
    }
  }
}
</script>

<style scoped>
.table-header {
  font-size: 14px;
  font-weight: bolder;
}

th:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

th:nth-child(1) {
  width: auto;
}

th:nth-child(2) {
  width: 200px;
}

th:nth-child(3) {
  width: 150px;
}

th:nth-child(4) {
  width: 300px;
}

th:nth-child(5) {
  width: 140px;
}
</style>
