import { render, staticRenderFns } from "./AlterGrroupRevenue.vue?vue&type=template&id=ef22a6b0&scoped=true"
import script from "./AlterGrroupRevenue.vue?vue&type=script&lang=js"
export * from "./AlterGrroupRevenue.vue?vue&type=script&lang=js"
import style0 from "./AlterGrroupRevenue.vue?vue&type=style&index=0&id=ef22a6b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef22a6b0",
  null
  
)

export default component.exports