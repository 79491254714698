<template>
  <b-container fluid>
    <b-row>
      <b-col class="userListColumn" lg="3">
        <div class="usersList">
          <div class="iq-search-bar">
            <form action="#" class="searchbox">
              <input type="text" class="text search-input" />
              <a class="search-link" href="#">
                <i class="ri-search-line"></i>
              </a>
            </form>
          </div>
          <div
            @click="handlerShow(user.idUsuario)"
            class="onHover"
            v-for="user in users"
            :key="user.id"
          >
            <iq-card
              :class="[
                'iq-card_scoped',
                user.id === newUserData.id ? 'selected' : ''
              ]"
            >
              <template v-slot:headerTitle>
                <div class="cardSameLevel">
                  <span class="card-title">{{ user.nmUsuario }}</span>
                </div>
              </template>
            </iq-card>
          </div>
        </div>
        <div class="buttonBottom">
          <b-button
            variant="success"
            @click="handlerShow()"
            v-if="userHaveHighPermission"
          >
            Adicionar
          </b-button>
        </div>
      </b-col>
      <b-col lg="9" v-show="show">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form
            class="form-iqcard"
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <div class="box-options">
              <button
                @click.prevent="handleButtonOne()"
                :class="
                  buttonOne ? 'button-option-active' : 'button-option-not'
                "
              >
                Usuario
                <b-icon
                  icon="person-fill"
                  font-scale="1"
                  :style="buttonOne ? 'color:#fff' : 'color:#089bab'"
                ></b-icon>
              </button>
              <button
                @click.prevent="handleButtonTwo()"
                :class="
                  buttonTwo ? 'button-option-active' : 'button-option-not'
                "
              >
                Configurações
                <b-icon
                  icon="gear-fill"
                  font-scale="1"
                  :style="buttonTwo ? 'color:#fff' : 'color:#089bab'"
                ></b-icon>
              </button>
            </div>
            <iq-card v-if="buttonOne">
              <template v-slot:headerTitle>
                <h4 class="card-title">Cadastro de Usuario</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-3">
                      <div class="photo">
                        <img
                          :src="
                            newUserData.idSexo === 1
                              ? require('../../assets/images/user/2.png')
                              : require('../../assets/images/user/1.jpg')
                          "
                          class="avatar-50"
                        />
                        <b-button size="sm" variant="primary"
                          >Selecionar foto</b-button
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Usuario:"
                      label-for="usuario"
                    >
                      <ValidationProvider
                        name="Usuario"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-uppercase
                          v-model="newUserData.login"
                          type="text"
                          autocomplete="noautocomplete"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe o login</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Senha:"
                      label-for="password"
                    >
                      <div style="display: flex; align-items: center">
                        <b-form-input
                          @click.prevent="activeOrietetion()"
                          autocomplete="new-password"
                          v-model="password"
                          :type="passwordFieldType ? 'password' : 'text'"
                          :style="
                            active
                              ? 'outline: none; border: 1px solid red;'
                              : ''
                          "
                        ></b-form-input>
                        <i
                          :class="
                            passwordFieldType
                              ? 'fas fa-eye'
                              : 'fas fa-eye-slash'
                          "
                          style="margin-left: 10px"
                          @click="passwordFieldType = !passwordFieldType"
                        ></i>
                      </div>
                      <span
                        v-if="active"
                        style="color: red; font-size: 0.7rem; margin-top: 4px"
                      >
                        {{ errorMensage }}
                      </span>

                      <div
                        v-if="isRule"
                        style="
                          display: flex;
                          flex-direction: column;
                          gap: 6px;
                          align-items: flex-start;
                        "
                      >
                        <span style="font-size: 0.8rem; margin-top: 8px"
                          >A senha precisa conter:</span
                        >
                        <div
                          style="
                            display: flex;
                            font-size: 0.8rem;
                            flex-direction: column;
                            gap: 2px;
                            align-items: flex-start;
                          "
                        >
                          <span
                            :style="
                              rule1
                                ? 'text-decoration: line-through; color: black'
                                : 'color: red'
                            "
                            >8 digitos</span
                          >
                          <span
                            :style="
                              rule2
                                ? 'text-decoration: line-through; color: black'
                                : 'color: red'
                            "
                            >1 caractere especial (como !,@,#, etc.)</span
                          >
                          <span
                            :style="
                              rule3
                                ? 'text-decoration: line-through; color: black'
                                : 'color: red'
                            "
                            >1 letra maíuscula</span
                          >
                          <span
                            :style="
                              rule4
                                ? 'text-decoration: line-through; color: black'
                                : 'color: red'
                            "
                            >1 numero</span
                          >
                        </div>
                      </div>

                      <b-form-checkbox
                        style="margin-top: 4px"
                        type="checkbox"
                        v-model="newUserData.alterarSenha"
                        :value="0"
                        :unchecked-value="1"
                        :checked-value="0"
                        >Alterar senha no proximo Login
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nome:"
                      label-for="nome"
                    >
                      <ValidationProvider
                        name="nome"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newUserData.nome"
                          type="text"
                          v-uppercase
                          autocomplete="noautocomplete"
                          name="nome"
                          id="nome"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um nome</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nome social:"
                      label-for="nomeSocial"
                    >
                      <b-form-input
                        v-model="newUserData.nomeSocial"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                        name="nomeSocial"
                        id="nomeSocial"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Data de Nascimento:"
                      label-for="birthDate"
                    >
                      <ValidationProvider
                        name="dtNascimento"
                        rules="required|dateIsValid"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-mask="'##/##/####'"
                          v-model="newUserData.dtNascimento"
                          type="text"
                          v-uppercase
                          autocomplete="noautocomplete"
                          name="birthDate"
                          id="birthDate"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe a data de nascimento</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-3"
                      label-for="sexo"
                      label="Sexo:"
                    >
                      <ValidationProvider
                        name="sexo"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          v-model="newUserData.idSexo"
                          :options="genders"
                          :reduce="(descSexo) => descSexo.id"
                          label="descSexo"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></v-select>
                        <div class="invalid-feedback">
                          <span>Informe um sexo</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-3" label="CPF:" label-for="cpf">
                      <ValidationProvider
                        vid="cpf"
                        name="cpf"
                        rules="required|cpfIsValid"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newUserData.cpf"
                          type="text"
                          v-uppercase
                          autocomplete="noautocomplete"
                          v-mask="'###.###.###-##'"
                          name="cpf"
                          id="cpf"
                          :class="
                            'form-control mb-0' +
                            (errors.length > 0 ? ' is-invalid' : '')
                          "
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um CPF válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-3" label="RG:" label-for="rg">
                      <b-form-input
                        v-model="newUserData.identidade"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                        name="rg"
                        id="rg"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nome da Mãe:"
                      label-for="motherName"
                    >
                      <b-form-input
                        v-model="newUserData.nomeMae"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nome do Pai:"
                      label-for="fatherName"
                    >
                      <b-form-input
                        v-model="newUserData.nomePai"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      v-mask="'(##)# #### - ####'"
                      label="Celular:"
                      label-for="celular"
                    >
                      <ValidationProvider
                        name="Mobile Number"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newUserData.telCelular"
                          type="text"
                          v-uppercase
                          autocomplete="noautocomplete"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um número de celular válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Fixo:"
                      label-for="fixo"
                      v-mask="'(##)#### - ####'"
                    >
                      <b-form-input
                        v-model="newUserData.telResidencial"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Email Profissional:"
                      label-for="emailProfissional"
                    >
                      <ValidationProvider
                        name="EmailProfissional"
                        rules="email"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-uppercase
                          v-model="newUserData.emailProfissional"
                          type="email"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um email válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Email Pessoal:"
                      label-for="emailPessoal"
                    >
                      <ValidationProvider
                        name="EmailPessoal"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newUserData.emailPessoal"
                          v-uppercase
                          type="email"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um email válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Perfil:"
                      label-for="profile"
                    >
                      <ValidationProvider
                        name="PerfilProfissional"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :class="
                            errors.length > 0
                              ? ['is-invalid', 'border-red']
                              : ''
                          "
                          v-model="newUserData.idPerfil"
                          :options="profiles"
                          :reduce="(descPerfil) => descPerfil.id"
                          label="descPerfil"
                        ></v-select>
                        <div class="invalid-feedback">
                          <span>Informe um perfil profissional</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>
                  <hr />
                  <template v-if="[1, 4, 5].includes(newUserData.idPerfil)">
                    <h5 class="mb-3">Dados Clinicos</h5>
                    <b-row>
                      <b-form-group
                        class="col-md-4"
                        label-for="council"
                        label="Conselho:"
                      >
                        <b-form-select
                          v-model="newUserData.idConselhoProfissional"
                          :disabled="councilDropdownDisabled"
                          plain
                          id="council"
                        >
                          <template v-slot:first>
                            <b-form-select-option
                              v-for="council in councils"
                              :key="council.id"
                              v-bind:value="council.id"
                              >{{ council.descConselho }}</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        class="col-md-3"
                        label="Nº Registro:"
                        label-for="numRegister"
                      >
                        <ValidationProvider
                          vid="numRegister"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="newUserData.numeroConselho"
                            :value="2"
                            type="text"
                            v-uppercase
                            autocomplete="noautocomplete"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>Informe o número do conselho</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        class="col-md-4"
                        label-for="state"
                        label="Estado Conselho:"
                      >
                        <ValidationProvider
                          vid="numRegister"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-select
                            v-model="newUserData.idEstadoConselho"
                            :options="states"
                            :reduce="(descEstado) => descEstado.id"
                            label="descEstado"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></v-select>
                          <div class="invalid-feedback">
                            <span>Informe o estado do conselho</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        class="col-md-8"
                        label-for="especialidade"
                        label="Especialidade:"
                      >
                        <v-select
                          v-model="newSpecialty"
                          :options="especialidades"
                          :reduce="(descCbo) => descCbo.id"
                          label="descCbo"
                        ></v-select>
                      </b-form-group>
                      <b-form-group class="col-md-4 alignAddSpecialtyButton">
                        <b-button
                          variant="primary"
                          @click="addNewSpecialtyDataList"
                          >Adicionar especialidade</b-button
                        >
                      </b-form-group>
                      <div class="table">
                        <div class="headerList">
                          <span>Código CBO</span>
                          <span>Descrição CBO</span>
                          <span>Principal</span>
                          <span>Ação</span>
                        </div>
                        <div
                          class="dataList"
                          v-for="specialty in newUserData.usuariocbo"
                          :key="specialty.idCbo"
                        >
                          <span>{{ specialty.idCbo }}</span>
                          <span>
                            {{ showSpecialistys(specialty) }}
                          </span>
                          <b-form-radio
                            name="checkMainSpecialty"
                            v-model="mainSpecialty"
                            :value="specialty.idCbo"
                            @input="handlerMainSpecialty"
                          ></b-form-radio>
                          <b-button
                            @click="removeSpecialty(specialty)"
                            variant="outline-danger"
                            size="sm"
                            >Remover</b-button
                          >
                        </div>
                      </div>
                    </b-row>
                  </template>
                  <div class="buttons">
                    <b-button variant="danger" v-b-modal.modal-confirm
                      >Cancelar</b-button
                    >
                    <b-modal
                      id="modal-confirm"
                      ref="modal-confirm"
                      hide-footer
                      title="Todos os dados não salvos serão perdidos"
                      cancel-title="Não"
                      ok-title="Sim"
                    >
                      <p class="my-2">Você realmente deseja cancelar?</p>
                      <div class="buttons">
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="danger"
                          @click="$bvModal.hide('modal-confirm')"
                          >Não</b-button
                        >
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="primary"
                          @click="cancelar"
                          >Sim</b-button
                        >
                      </div>
                    </b-modal>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="isAdd && !userHaveHighPermission"
                      >Salvar</b-button
                    >
                  </div>
                </div>
              </template>
            </iq-card>
            <iq-card v-if="buttonTwo">
              <template v-slot:headerTitle>
                <h4 class="card-title">Configurações</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <div
                    style="
                      display: flex;
                      gap: 10px;
                      flex-wrap: wrap;
                      margin-bottom: 40px;
                    "
                  >
                    <ValidationProvider
                      name="ativo"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <b-form-checkbox
                        v-model="newUserData.status"
                        type="checkbox"
                        :value="1"
                        :unchecked-value="0"
                        :class="errors.length > 0 ? ' is-invalid' : ''"
                        >Ativo</b-form-checkbox
                      >
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider
                      name="bloqueado"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <b-form-checkbox
                        v-model="newUserData.bloqueado"
                        :value="1"
                        :unchecked-value="0"
                        type="checkbox"
                        :class="errors.length > 0 ? ' is-invalid' : ''"
                        >Bloqueado</b-form-checkbox
                      >
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>

                    <b-form-checkbox
                      v-model="newUserData.visualizaCompartilhamento"
                      :value="0"
                      :unchecked-value="1"
                    >
                      Visualizar Compartilhado
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-model="newUserData.prontuarioRestrito"
                      :value="0"
                      :unchecked-value="1"
                    >
                      Prontuário Restrito
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-model="newUserData.utilizaTriagem"
                      :value="0"
                      :unchecked-value="1"
                    >
                      Utiliza triagem
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-model="newUserData.responsavelTriagem"
                      :value="0"
                      :unchecked-value="1"
                    >
                      Realiza triagem
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-model="newUserData.utilizaProntuario"
                      value="S"
                      unchecked-value="N"
                    >
                      Utiliza Prontuário
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="newUserData.receitaAutomatica"
                      :value="0"
                      :unchecked-value="1"
                    >
                      Receita Automática
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="newUserData.agendamentoRecorrente"
                      :value="0"
                      :unchecked-value="1"
                    >
                      Agendamento Recorrênte
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="newUserData.feedbackAtendimento"
                      :value="0"
                      :unchecked-value="1"
                    >
                      Feedback de Atendimento
                    </b-form-checkbox>
                  </div>
                  <div class="buttons">
                    <b-button variant="danger" v-b-modal.modal-confirm
                      >Cancelar</b-button
                    >
                    <b-modal
                      id="modal-confirm"
                      ref="modal-confirm"
                      hide-footer
                      title="Todos os dados não salvos serão perdidos"
                      cancel-title="Não"
                      ok-title="Sim"
                    >
                      <p class="my-2">Você realmente deseja cancelar?</p>
                      <div class="buttons">
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="danger"
                          @click="$bvModal.hide('modal-confirm')"
                          >Não</b-button
                        >
                        <b-button
                          class="mt-2"
                          size="sm"
                          variant="primary"
                          @click="cancelar"
                          >Sim</b-button
                        >
                      </div>
                    </b-modal>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="isAdd && !userHaveHighPermission"
                      >Salvar</b-button
                    >
                  </div>
                </div>
              </template>
            </iq-card>
          </form>
        </ValidationObserver>
      </b-col>
    </b-row>

    <b-modal
      id="clinicsToBoundUser"
      title="Seleção de clínicas"
      title-class="text-light"
      header-bg-variant="primary"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Continuar"
      @ok="saveUser"
    >
      <b-table-simple>
        <b-thead>
          <b-tr class="clinics-table-header">
            <b-th>Selecionar</b-th>
            <b-th style="width: 15%">Código</b-th>
            <b-th>Descrição</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr v-for="clinic in user.clinica_usuario" :key="clinic.idClinica">
            <b-td>
              <b-form-checkbox
                :id="`checkbox-${clinic.idClinica}`"
                :checked="verifyIfClinicIsInUserClinics(clinic)"
                @change="handleClinicVerification($event, clinic)"
              />
            </b-td>
            <b-td>{{ clinic.idClinica }}</b-td>
            <b-td>{{ clinic.nmClinica }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import API from '../../services/index'
import { cpf } from 'cpf-cnpj-validator'

import iqCard from '../../components/xray/cards/iq-card.vue'

export default {
  components: { iqCard },
  name: 'AddUser',
  async mounted() {
    this.requests()
    if (this.isManager) {
      const response = await this.getUsersByClinic(
        this.user.clinica_usuario[0].idClinica
      )

      this.users = response
    } else {
      this.users = [{ idUsuario: this.user.id, nmUsuario: this.user.nome }]
    }
  },
  data() {
    return {
      buttonOne: true,
      buttonTwo: false,
      users: [],
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      passwordFieldType: true,
      isAdd: true,
      show: true,
      newPassword: true,
      password: null,
      especialidades: [],
      newSpecialty: '',
      councils: [],
      states: [],
      profiles: [],
      newUserData: {
        login: '',
        senha: '',
        nome: '',
        telCelular: '',
        emailPessoal: '',
        idSexo: '',
        dtNascimento: '',
        cpf: '',
        idConselhoProfissional: '',
        numeroConselho: '',
        idEstadoConselho: '',
        usuariocbo: [],
        clinica_usuario: [],
        bloqueado: 0,
        emailProfissional: '',
        identidade: '',
        status: 0,
        telResidencial: '',
        id: '',
        nomeMae: '',
        nomePai: '',
        nomeSocial: '',
        idPerfil: '',
        visualizaCompartilhamento: 1,
        utilizaProntuario: 'N',
        prontuarioRestrito: 1,
        responsavelTriagem: 1,
        utilizaTriagem: 1,
        cadastroInterno: 1,
        alterarSenha: null,
        localizacaoUsuario: 0,
        receitaAutomatica: 1,
        agendamentoRecorrente: 1,
        feedbackAtendimento: 1
      },
      userSpecialtysAlreadyExists: [],
      genders: [],
      specialtys: [],
      mainSpecialty: '',
      specialtysToRemove: [],
      councilDropdownDisabled: false,
      userToSave: '',
      active: false,
      errorMensage: 'Digite uma senha!!',
      isRule: false,
      rule1: false,
      rule2: false,
      rule3: false,
      rule4: false
    }
  },
  computed: {
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      colors: 'Setting/colorState',
      user: 'Setting/user'
    }),
    userHaveHighPermission() {
      const ids = [1, 3]
      return ids.includes(this.user.idPerfil)
    },
    isManager() {
      return [2, 3].includes(this.user.idPerfil)
    },
    getCheckbox() {
      return this.newUserData.alterarSenha === 0
    }
  },
  watch: {
    password(value) {
      this.isRule = true
      const regex = /[^a-zA-Z0-9\s]/
      const regxUpCase = /[A-Z]/
      const regxDigito = /\d/
      if (value === '' || value === null) {
        this.active = true
        this.errorMensage = 'Digite uma senha!'
      } else {
        this.active = false
      }
      if (value.length >= 8) {
        this.rule1 = true
      } else {
        this.rule1 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regex.test(value)) {
        this.rule2 = true
        this.active = false
      } else {
        this.rule2 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regxUpCase.test(value)) {
        this.rule3 = true
      } else {
        this.rule3 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (regxDigito.test(value)) {
        this.rule4 = true
      } else {
        this.rule4 = false
        this.active = true
        this.errorMensage = 'A senha deve sequir o padrão mostrado abaixo!'
      }
      if (
        regxDigito.test(value) &&
        regxUpCase.test(value) &&
        regex.test(value) &&
        value !== '' &&
        value.length >= 8
      ) {
        this.isRule = false
      }
    },
    'newUserData.utilizaTriagem': function (newValue) {
      if (newValue === 0) {
        this.newUserData.responsavelTriagem = 1
      }
    },
    'newUserData.responsavelTriagem': function (newValue) {
      if (newValue === 0) {
        this.newUserData.utilizaTriagem = 1
        this.newUserData.utilizaProntuario = 'S'
      }
    },
    'newUserData.idPerfil': function (newValue) {
      if ([4, 5].includes(newValue)) {
        this.newUserData.idConselhoProfissional = 2
        this.councilDropdownDisabled = true
        this.newSpecialty = 401
      } else {
        this.councilDropdownDisabled = false
      }
    }
  },
  methods: {
    ...mapActions({
      getUsersByClinic: 'User/getUsersByClinicAction'
    }),
    async getUser(user) {
      await API.get(`/api/Usuario/ObtemItem/${user}`, this.authToken).then(
        (res) => {
          this.newUserData = res.data.object
          const date = new Date(res.data.object.dtNascimento)
          this.newUserData.dtNascimento = date.toLocaleDateString('pt-BR')
          this.newUserData.idEstadoConselho = res.data.object.idEstadoConselho
          this.newUserData.alterarSenha = res.data.object.alterarSenha
          if (this.newUserData.telCelular)
            this.newUserData.telCelular = this.newUserData.telCelular.toString()
          if (this.newUserData.telResidencia)
            this.newUserData.telResidencial =
              this.newUserData.telResidencial.toString()
          const mainSpecialty = this.newUserData.usuariocbo.find(
            (uCbo) => uCbo.principal === 'S'
          )
          this.mainSpecialty =
            mainSpecialty === undefined || null ? 0 : mainSpecialty.idCbo
        }
      )
      await API.get(
        `/api/UsuarioEspecialidadeCbo/ObtemListaPorUSuario/?id_usuario=${user}`,
        this.authToken
      ).then((res) => (this.userSpecialtysAlreadyExists = res.data.object))
    },
    resetForm() {
      this.newUserData = {
        login: '',
        senha: '',
        nome: '',
        telCelular: '',
        emailPessoal: '',
        idSexo: '',
        dtNascimento: '',
        cpf: '',
        idConselhoProfissional: '',
        numeroConselho: '',
        idEstadoConselho: '',
        usuariocbo: [],
        clinica_usuario: [],
        bloqueado: 0,
        emailProfissional: '',
        identidade: '',
        status: 1,
        telResidencial: '',
        id: '',
        nomeMae: '',
        nomePai: '',
        nomeSocial: '',
        idPerfil: '',
        visualizaCompartilhamento: 0,
        prontuarioRestrito: 1,
        responsavelTriagem: 1,
        utilizaTriagem: 1,
        cadastroInterno: 1,
        alterarSenha: 1,
        localizacaoUsuario: 0,
        receitaAutomatica: 1,
        agendamentoRecorrente: 1,
        feedbackAtendimento: 1
      }

      this.mainSpecialty = ''
      this.newSpecialty = ''
      this.userToSave = ''
    },
    handlerShow(user) {
      if (user) {
        this.getUser(user)
        this.isAdd = false
      } else {
        this.resetForm()
        this.isAdd = true
      }
    },
    requests() {
      API.get('/api/Perfil/ObtemListaStatus/0', this.authToken).then((res) => {
        this.profiles = res.data.object
      })
      API.get('/api/Sexo/ObtemLista').then((res) => {
        this.genders = res.data.object
      })
      API.get('/api/ConselhoProfissional/ObtemLista').then((res) => {
        this.councils = res.data.object
      })
      API.get('/api/Estado/ObtemLista').then((res) => {
        this.states = res.data.object
      })
      API.get('/api/Cbo/ObtemListaSaude/1?saude=S', this.authToken).then(
        (res) => {
          this.especialidades = res.data.object
        }
      )
      this.password = null
    },
    showSpecialistys(specialty) {
      const specialtyToShow = this.especialidades.find(
        (especialidade) => especialidade.id === specialty.idCbo
      )
      return specialtyToShow.descCbo
    },
    addNewSpecialtyDataList() {
      if (
        !this.especialidades.some(
          (specialty) => specialty.id === this.newSpecialty
        )
      ) {
        return this.$toast.error(`Especialidade não existe`)
      } else {
        const specialtyToAdd = this.especialidades.find(
          (especialidade) => especialidade.id === this.newSpecialty
        )
        if (
          this.newUserData.usuariocbo.some(
            (specialty) => specialty.idCbo === specialtyToAdd.id
          )
        ) {
          return this.$toast.error(`Especialidade já adicionada`)
        }
        const usuariocboLength = this.newUserData.usuariocbo.length
        if (usuariocboLength === 0) this.mainSpecialty = specialtyToAdd.id

        this.newUserData.usuariocbo.push({
          idCbo: specialtyToAdd.id,
          idUsuario: 0,
          idUsuarioAlteracao: 0,
          dtMovimentacao: this.newUserData.birthDate,
          principal: usuariocboLength > 0 ? 'N' : 'S'
        })
      }
    },
    removeSpecialty(specialty) {
      const specialtyToRemoveExist = this.userSpecialtysAlreadyExists.find(
        (specialtyExist) => specialtyExist.id === specialty.id
      )
      if (
        specialtyToRemoveExist &&
        !this.specialtysToRemove.find(
          (specialtyExist) => specialtyExist.id === specialty.id
        )
      )
        this.specialtysToRemove.push(specialtyToRemoveExist)

      const newData = this.newUserData.usuariocbo.filter(
        (cbo) => cbo.idCbo !== specialty.idCbo
      )
      this.newUserData.usuariocbo = newData
    },
    formatCpf() {
      if (this.userToSave.cpf) {
        const newCpf = cpf.strip(this.userToSave.cpf)
        this.userToSave.cpf = newCpf
      }
    },
    formatPhone() {
      if (this.userToSave.telCelular) {
        const newPhone = this.userToSave.telCelular?.replace(/[^0-9]/g, '')
        this.userToSave.telCelular = parseInt(newPhone)
      }

      if (this.newUserData.telResidencial) {
        const newFixPhone = this.userToSave.telResidencial?.replace(
          /[^0-9]/g,
          ''
        )
        this.userToSave.telResidencial = parseInt(newFixPhone)
      }
    },
    handlerMainSpecialty() {
      this.userToSave.usuariocbo.map((obj) => {
        if (obj.id === this.mainSpecialty) obj.principal = 'S'
        else obj.principal = 'N'

        return obj
      })
    },
    cancelar() {
      this.$bvModal.hide('modal-confirm')
      this.resetForm()
    },
    changePassword() {
      if (this.password === null) {
        this.userToSave.senha = null
      } else {
        this.userToSave.senha = this.password
      }
    },
    removeSpecialtyRequest() {
      this.specialtysToRemove.map(async (specialtyToRemove) =>
        API.delete('/api/UsuarioEspecialidadeCbo/Remover', {
          data: {
            object: {
              id: specialtyToRemove.id,
              idCbo: specialtyToRemove.idCbo,
              idUsuario: specialtyToRemove.idUsuario,
              idUsuarioAlteracao: specialtyToRemove.idUsuarioAlteracao,
              dtMovimentacao: specialtyToRemove.dtMovimentacao,
              principal: specialtyToRemove.principal
            }
          },
          ...this.authToken
        }).then()
      )
    },
    formatBirthDate() {
      this.userToSave.dtNascimento = this.userToSave.dtNascimento
        .split('/')
        .reverse()
        .join('-')
    },
    onSubmit() {
      this.userToSave = {
        ...this.newUserData
      }
      this.formatCpf()
      this.formatPhone()
      /*    this.formatActiveBlock() */
      this.handlerMainSpecialty()
      this.changePassword()
      this.formatBirthDate()

      if (this.isAdd) {
        if (this.active) {
          this.active = true
          this.$toast.error('Por favor verifique o campo de senha!')
        } else {
          this.$bvModal.show('clinicsToBoundUser')
        }
      } else {
        if (this.active) {
          this.active = true
          this.$toast.error('Por favor verifique o campo de senha!')
        } else {
          this.saveUser()
        }
      }
    },
    setNewPassword(value) {
      if (value === true) {
        this.newUserData.alterarSenha = 0
      } else {
        this.newUserData.alterarSenha = 1
      }
    },
    saveUser() {
      if (this.isAdd) {
        this.$store
          .dispatch(
            `User/${this.isAdd ? 'add' : 'edit'}UserAction`,
            this.userToSave
          )
          .then((userData) => {
            this.removeSpecialtyRequest()
            this.$toast.success('Operação realizada com sucesso!')
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() =>
            this.$toast.error('Ocorreu algum erro, tente novamente!')
          )
      } else {
        this.$store
          .dispatch(
            `User/${this.isAdd ? 'add' : 'edit'}UserAction`,
            this.userToSave
          )
          .then((userData) => {
            this.removeSpecialtyRequest()
            this.$toast.success('Operação realizada com sucesso!')
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() =>
            this.$toast.error('Ocorreu algum erro, tente novamente!')
          )
      }
    },
    activeOrietetion() {
      const regex = /[^a-zA-Z0-9\s]/
      const regxUpCase = /[A-Z]/
      const regxDigito = /\d/
      if (
        regxDigito.test(this.password) &&
        regxUpCase.test(this.password) &&
        regex.test(this.password) &&
        this.password !== '' &&
        this.password !== null &&
        this.password.length >= 8
      ) {
        this.isRule = false
      } else {
        this.isRule = true
      }
    },
    verifyIfClinicIsInUserClinics(clinic) {
      if (this.userToSave) {
        const index = this.userToSave.clinica_usuario.findIndex((element) => {
          return element.idClinica === clinic.idClinica
        })

        return index !== -1
      }
    },
    handleClinicVerification(event, clinic) {
      if (event) {
        this.boundUserToClinic(clinic)
      } else {
        this.unboundUserFromClinic(clinic)
      }
    },
    boundUserToClinic(clinic) {
      this.userToSave.clinica_usuario.push(clinic)
    },
    handleButtonOne() {
      if (this.buttonOne !== true) {
        this.buttonOne = true
        this.buttonTwo = false
      }
    },
    handleButtonTwo() {
      if (this.buttonTwo !== true) {
        this.buttonTwo = true
        this.buttonOne = false
      }
    },
    unboundUserFromClinic(clinic) {
      const index = this.userToSave.clinica_usuario.findIndex((element) => {
        return element.idClinica === clinic.idClinica
      })

      this.newUserData.clinica_usuario.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.onHover:hover {
  cursor: pointer;
}

.box-options {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.box-options button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 20px;
  font-weight: 600;
  border-radius: 20px;
  border: #089bab 1px solid;
  flex: 1;
}

.button-option-not {
  background-color: #fff;
  color: #089bab;
}

.button-option-active {
  background-color: #089bab;
  color: #fff;
}

.border-red {
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.cardSameLevel {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sameLevel {
  display: flex;
  align-items: flex-end;
}

.usersList {
  overflow-x: auto;
  margin-bottom: 20px;
  flex: 1;

  div.iq-card {
    margin-bottom: 15px !important;
  }
}

.userListColumn {
  display: flex;
  flex-direction: column;
}

.selected {
  background-color: #089bab !important;
  color: white;
}

.form-iqcard {
  height: 80vh;
  margin-bottom: 0 !important;
  overflow-x: auto;
}

.photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.table {
  background: white;
  border-radius: 6px;
  overflow-y: auto;
  height: 200px;
}

.headerList {
  z-index: 1;
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #089bab;
  color: #fff;
}

.dataList {
  z-index: 2;
  font-weight: 400;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid #d4dae4;
  padding: 10px;
  color: #3d5879;
}

.alignAddSpecialtyButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.clinics-table-header {
  color: #fff;
  text-align: left;
  font-size: 14px;
  th {
    font-weight: 400 !important;
    background-color: #089bab;
    position: sticky;
    max-height: 19px !important;
    height: 19px !important;
    padding: 8px !important;
    border: none !important;
    text-align: center;
  }

  th:nth-child(1) {
    width: 20%;
  }
}

table td {
  text-align: center;
}
</style>
