<template>
  <b-container fluid>
    <find-services-modal
      :idUsuarioConvenio="modalServices.idUsuarioConvenio"
      @modal-closed="closeFindServicesModal"
    />
    <b-row>
      <b-col class="userListColumn" lg="3">
        <select
          v-model="selectClinic"
          class="select-clinic"
          name="select-clinic"
          id="select-clinic"
        >
          <option v-for="item in clinics" :key="item.idClinica" :value="item">
            {{ item.nmClinica }}
          </option>
        </select>
        <div class="iq-search-bar" style="padding: 0px !important">
          <v-select
            style="margin-bottom: 10px"
            v-if="isManager"
            v-model="selectedUser"
            :options="users"
            label="nmUsuario"
            :clearable="false"
            @input="getHealthInsurancesByDoctor"
          />
          <form
            @submit.prevent="searchHealthInsurance($event.target.value)"
            class="searchbox"
          >
            <input
              type="text"
              @change="searchHealthInsurance($event.target.value)"
              @keydown.enter.prevent="
                searchHealthInsurance($event.target.value)
              "
              class="text search-input"
              placeholder="Buscar convênio"
            />
            <div class="search-link">
              <i class="ri-search-line"></i>
            </div>
          </form>
        </div>
        <div class="usersList">
          <div
            v-for="healthInsurance in searchedHealthInsurances"
            :key="healthInsurance.id"
            class="onHover"
            @click="handlerShow(healthInsurance)"
          >
            <iq-card
              :class="[
                'iq-card_scoped',
                healthInsurance.id === newHealthInsurance.id ? 'selected' : ''
              ]"
            >
              <template v-slot:headerTitle>
                <div
                  class="cardSameLevel"
                  @click="handlerShow(healthInsurance)"
                >
                  <div class="avatar mr-3">
                    <img
                      src="../../assets/images/user/01.jpg"
                      class="avatar-50 rounded"
                    />
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <span
                      style="font-weight: 700; font-size: 11px"
                      v-if="isManager"
                      >{{ healthInsurance.nmProfissional }}</span
                    >
                    <span class="card-title">{{
                      healthInsurance.razaoSocial
                    }}</span>
                  </div>
                </div>
              </template>
            </iq-card>
          </div>
        </div>
        <div class="buttonBottom">
          <b-button
            @click="handlerShow()"
            variant="success"
            v-if="userHaveHighPermission"
            >Adicionar</b-button
          >
        </div>
      </b-col>
      <b-col lg="9" v-show="show">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form
            class="form"
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <div id="tab-bar" class="tab-bar" name="tab-bar">
              <button
                :class="tab1 ? 'button-active' : 'button-deactive'"
                @click.prevent="handleTabOne()"
              >
                Cadastrar Convênio
                <b-icon
                  icon="pen-fill"
                  font-scale="1"
                  :style="tab1 ? 'color:#fff' : 'color:#089bab'"
                ></b-icon>
              </button>
              <button
                :class="tab2 ? 'button-active' : 'button-deactive'"
                @click.prevent="handleTabTwo()"
              >
                Vincular Usuarios e Serviços
                <b-icon
                  icon="share-fill"
                  font-scale="1"
                  :style="tab2 ? 'color:#fff' : 'color:#089bab'"
                ></b-icon>
              </button>
              <button
                :class="tab3 ? 'button-active' : 'button-deactive'"
                @click.prevent="handleTabThree()"
              >
                Configurações
                <b-icon
                  icon="gear-fill"
                  font-scale="1"
                  :style="tab3 ? 'color:#fff' : 'color:#089bab'"
                ></b-icon>
              </button>
            </div>
            <iq-card v-show="tab1" class="form-iqcard">
              <template v-slot:headerTitle>
                <h4>Cadastrar Convênio</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-3">
                      <div class="photo">
                        <img
                          src="../../assets/images/user/01.jpg"
                          class="avatar-50"
                        />
                        <b-button size="sm" variant="primary"
                          >Selecionar foto</b-button
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label="Nome social:"
                      label-for="razaoSocial"
                    >
                      <ValidationProvider
                        name="razaoSocial"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newHealthInsurance.razaoSocial"
                          v-uppercase
                          name="razaoSocial"
                          type="text"
                          autocomplete="noautocomplete"
                          ref="razaoSocial"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um nome</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="CNPJ:"
                      label-for="cnpj"
                    >
                      <ValidationProvider
                        name="cnpj"
                        rules="cnpjIsValid"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newHealthInsurance.cnpj"
                          v-mask="'##.###.###/####.##'"
                          type="text"
                          autocomplete="noautocomplete"
                          name="cnpj"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um CNPJ válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-1"
                      label="Ativo:"
                      label-for="ativo"
                      v-slot="{}"
                    >
                      <ValidationProvider
                        name="ativo"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-checkbox
                          v-model="newHealthInsurance.ativo"
                          type="checkbox"
                          :value="0"
                          :unchecked-value="1"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-checkbox>
                        <div class="invalid-feedback">
                          <span>Deve ser ativo</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Data de Cadastro:"
                      label-for="dtCadastro"
                    >
                      <ValidationProvider
                        name="dtCadastro"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newHealthInsurance.dtCadastro"
                          type="date"
                          name="dtCadastro"
                          id="dtCadastro"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe uma data de cadastro</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Data de Faturamento:"
                      label-for="dtFaturamento"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.dtFaturamento"
                        type="date"
                        name="dtFaturamento"
                        id="dtFaturamento"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label="Máscara do cartão:"
                      label-for="mascaraCartao"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.mascaraCartao"
                        type="text"
                        autocomplete="noautocomplete"
                        name="mascaraCartao"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Responsável:"
                      label-for="responsavel"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.responsavel"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      v-mask="'(##)# #### - ####'"
                      label="Celular:"
                      label-for="celular"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.telCelular"
                        type="text"
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      v-mask="'(##)#### - ####'"
                      label="Fixo:"
                      label-for="fixo"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.telComercial"
                        type="text"
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      class="col-md-6"
                      label="Email:"
                      label-for="email"
                    >
                      <ValidationProvider
                        name="Email"
                        rules="email"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="newHealthInsurance.email"
                          type="email"
                          v-uppercase
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>Informe um email válido</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <hr />
                    <b-form-group
                      class="col-md-3"
                      v-mask="'#####-###'"
                      label="CEP:"
                      label-for="cep"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.cep"
                        type="text"
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-3"
                      label-for="idTipoLogradouro"
                      label="Tipo endereço:"
                    >
                      <v-select
                        v-model="newHealthInsurance.idTipoLogradouro"
                        :options="adressTypes"
                        :reduce="(descLogradouro) => descLogradouro.id"
                        label="descLogradouro"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label="Logradouro:"
                      label-for="logradouro"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.logradouro"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-2"
                      label="Nº:"
                      label-for="numero"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.numero"
                        type="number"
                        autocomplete="noautocomplete"
                        min="1"
                        step="1"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-5"
                      label="Complemento:"
                      label-for="complemento"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.complementoEndereco"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-4"
                      label="Bairro:"
                      label-for="bairro"
                    >
                      <b-form-input
                        v-model="newHealthInsurance.bairro"
                        type="text"
                        v-uppercase
                        autocomplete="noautocomplete"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-4"
                      label-for="idEstado"
                      label="Estado:"
                    >
                      <v-select
                        v-model="newHealthInsurance.idEstado"
                        :options="states"
                        :reduce="(descEstado) => descEstado.id"
                        label="descEstado"
                        @input="getCities()"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-sm-4"
                      label-for="idCidade"
                      label="Cidade:"
                    >
                      <v-select
                        v-model="newHealthInsurance.idCidade"
                        :options="cities"
                        :reduce="(descCidade) => descCidade.id"
                        label="descCidade"
                      ></v-select>
                    </b-form-group>
                  </b-row>
                </div>
                <div class="buttons">
                  <b-button variant="danger" v-b-modal.modal-confirm
                    >Cancelar
                  </b-button>
                  <b-modal
                    id="modal-confirm"
                    ref="modal-confirm"
                    hide-footer
                    title="Todos os dados não salvos serão perdidos"
                    cancel-title="Não"
                    ok-title="Sim"
                  >
                    <p class="my-2">Você realmente deseja cancelar?</p>
                    <div class="buttons">
                      <b-button
                        class="mt-2"
                        size="sm"
                        variant="danger"
                        @click="hideModal"
                        >Não</b-button
                      >
                      <b-button
                        class="mt-2"
                        size="sm"
                        variant="primary"
                        @click="cancelar"
                        >Sim</b-button
                      >
                    </div>
                  </b-modal>
                  <b-button variant="primary" type="submit">Salvar</b-button>
                </div>
              </template>
            </iq-card>
            <iq-card v-show="tab2" class="form-iqcard">
              <template v-slot:headerTitle>
                <h4>Vincular Usuarios e Serviços</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-form-group class="col-sm-4" label="Usuário:">
                    <v-select
                      :options="doctorsFromClinic"
                      label="nmUsuario"
                      @input="addToDoctorsWithBound()"
                      :clearable="false"
                      v-model="selectedDoctor"
                    ></v-select>
                  </b-form-group>
                </b-row>
                <div class="table">
                  <div class="headerList">
                    <span>Usuário</span>
                    <span>Nome</span>
                    <span>Serviços e Valores</span>
                    <span>Ativo</span>
                    <span>Ação</span>
                  </div>
                  <template v-if="doctorsFromClinic.length > 0">
                    <div
                      class="dataList"
                      :key="doctor.idUsuario"
                      v-for="doctor in doctorsWithBounds"
                    >
                      <span>{{ doctor.loginUsuario }}</span>
                      <span>{{ doctor.nmUsuario }}</span>
                      <div
                        v-if="userHaveHighPermission"
                        @click="
                          !doctor.notSaved && openFindServicesModal(doctor)
                        "
                        :title="
                          doctor.notSaved &&
                          'Clique em salvar para poder utilizar essa funcionalidade'
                        "
                      >
                        <i
                          :style="`transform: scale(1.7); color: ${
                            doctor.notSaved ? '#ccc' : '#089bab'
                          }`"
                          class="fas fa-hand-holding-usd onHover"
                        ></i>
                      </div>
                      <div v-if="userHaveHighPermission">
                        <b-form-radio
                          v-model="doctor.ativo"
                          :value="0"
                          :unchecked-value="1"
                        ></b-form-radio>
                      </div>
                      <b-button
                        variant="danger"
                        @click="handleRemoveFromDoctorsWithBound(doctor)"
                        v-if="userHaveHighPermission"
                        >{{
                          doctor.temAtendimento === 'F' ? 'Remover' : 'Inativar'
                        }}</b-button
                      >
                    </div>
                  </template>
                </div>
                <div class="buttons">
                  <b-button variant="danger" v-b-modal.modal-confirm
                    >Cancelar
                  </b-button>
                  <b-modal
                    id="modal-confirm"
                    ref="modal-confirm"
                    hide-footer
                    title="Todos os dados não salvos serão perdidos"
                    cancel-title="Não"
                    ok-title="Sim"
                  >
                    <p class="my-2">Você realmente deseja cancelar?</p>
                    <div class="buttons">
                      <b-button
                        class="mt-2"
                        size="sm"
                        variant="danger"
                        @click="hideModal"
                        >Não</b-button
                      >
                      <b-button
                        class="mt-2"
                        size="sm"
                        variant="primary"
                        @click="cancelar"
                        >Sim</b-button
                      >
                    </div>
                  </b-modal>
                  <b-button variant="primary" type="submit">Salvar</b-button>
                </div>
              </template>
            </iq-card>
          </form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import API from '../../services/index'
import { cnpj } from 'cpf-cnpj-validator'

import iqCard from '../../components/xray/cards/iq-card.vue'
import FindServicesModal from './FindServicesModal.vue'

export default {
  components: { iqCard, FindServicesModal },
  name: 'AddHealthInsurance',
  async mounted() {
    this.getDoctorsFromClinic()
    this.initClinicsSelect()
    this.getHealthInsurances()
    this.requests()
    this.show = true

    if (this.isManager) {
      const response = await this.getUsersByClinic(
        this.user.clinica_usuario[0].idClinica
      )

      this.users = [
        ...response.filter((item) => item.perfil.id === 1),
        {
          idUsuario: 0,
          nmUsuario: 'TODOS'
        }
      ]
    }
  },
  data() {
    return {
      selectedUser: '',
      users: '',
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      show: true,
      modalServices: {
        idUsuarioConvenio: 0
      },
      searchedHealthInsurances: [],
      userHealthInsurances: [],
      states: [],
      cities: [],
      doctorsFromClinic: [],
      doctorsWithBounds: [],
      selectedDoctor: {},
      isAdd: true,
      newHealthInsurance: {
        razaoSocial: '',
        cnpj: '',
        ativo: 0,
        dtCadastro: new Date().toLocaleDateString('en-CA'),
        dtFaturamento: '',
        mascaraCartao: '',
        responsavel: '',
        idTipoLogradouro: '',
        cep: '',
        logradouro: '',
        numero: '',
        complementoEndereco: '',
        bairro: '',
        idEstado: '',
        idCidade: '',
        telCelular: '',
        telComercial: '',
        email: ''
      },
      adressTypes: [],
      // tabs
      tab1: true,
      tab2: false,
      tab3: false,

      clinics: [],
      selectClinic: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user'
    }),

    userHaveHighPermission() {
      const ids = [1, 2, 3, 4, 5]
      return ids.includes(this.user.idPerfil)
    },

    isDoctor() {
      return this.user.idPerfil === 0
    },
    isManager() {
      return [2, 3].includes(this.user.idPerfil)
    }
  },
  watch: {
    async selectClinic() {
      await this.getHealthInsurances()
    },
    'newHealthInsurance.id': {
      handler: async function () {
        await this.getDoctorsFromClinic()
        await this.getDoctorsBoundedWithHealthInsurance()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getUsersByClinic: 'User/getUsersByClinicAction'
    }),
    initClinicsSelect() {
      this.clinics = this.user.clinica_usuario
      this.selectClinic = this.user.clinica_usuario[0]
    },
    handleTabOne() {
      if (this.tab1 !== true) {
        this.tab1 = true
        this.tab2 = false
        this.tab3 = false
      }
    },
    handleTabTwo() {
      if (this.tab2 !== true) {
        this.tab1 = false
        this.tab2 = true
        this.tab3 = false
      }
    },
    handleTabThree() {
      if (this.tab3 !== true) {
        this.tab1 = false
        this.tab2 = false
        this.tab3 = true
      }
    },
    async getHealthInsurancesByDoctor() {
      if (this.selectedUser.idUsuario) {
        await API.get(
          `/api/Convenio/ObtemListaPorClinica?id_clinica=${this.selectClinic.idClinica}`,
          this.authToken
        )
          .then((res) => {
            this.userHealthInsurances = res.data.object
            this.searchedHealthInsurances = res.data.object
          })
          .catch((error) => {
            this.$toast.error('Erro ao obter convênios:', error)
          })
      } else {
        this.getHealthInsurances()
      }
    },
    openFindServicesModal(doctorData) {
      this.modalServices.idUsuarioConvenio = doctorData.id
      this.$bvModal.show('find-services')
    },
    closeFindServicesModal() {
      this.modalServices.idUsuarioConvenio = null
    },
    async addToDoctorsWithBound() {
      try {
        const doctorToAdd = this.selectedDoctor

        const alreadyExists = this.doctorsWithBounds.some(
          (doctor) => doctor.idUsuario === doctorToAdd.idUsuario
        )

        if (alreadyExists) {
          this.$toast.error('Médico já inserido')
          return
        }

        if (this.isAdd) {
          this.doctorsWithBounds.push({
            ...doctorToAdd,
            ativo: 0,
            isNew: true,
            notSaved: true
          })
        } else {
          await API.post('/api/UsuarioConvenio/Adicionar', {
            object: {
              ...doctorToAdd,
              ativo: 0,
              idconvenio: this.newHealthInsurance.id,
              nmConvenio: this.newHealthInsurance.razaoSocial
            }
          })

          await this.getDoctorsBoundedWithHealthInsurance()
        }

        this.selectedDoctor = {}
      } catch (error) {}
    },
    async handleRemoveFromDoctorsWithBound(doctorToRemove) {
      try {
        if (doctorToRemove.temAtendimento === 'F') {
          if (!this.isAdd) {
            const response = await API.delete('/api/UsuarioConvenio/Remover', {
              data: { object: doctorToRemove }
            })
            if (response.data.result) {
              this.$toast.success(response.data.message)

              this.doctorsWithBounds = this.doctorsWithBounds.filter(
                (doctor) => doctor.idUsuario !== doctorToRemove.idUsuario
              )
            } else {
              this.$toast.error(response.data.message)
            }
          }
        } else {
          if (!this.isAdd) {
            const response = await API.put('/api/UsuarioConvenio/Editar', {
              object: { ...doctorToRemove, ativo: 1 }
            })

            this.$toast.success(response.data.message)
          }

          const doctorIndex = this.doctorsWithBounds.findIndex(
            (doctor) => doctor.idUsuario === doctorToRemove.idUsuario
          )
          this.doctorsWithBounds.splice(doctorIndex, 1, {
            ...doctorToRemove,
            ativo: 1
          })
        }
      } catch (error) {}
    },
    async getDoctorsBoundedWithHealthInsurance(
      id = this.newHealthInsurance.id
    ) {
      try {
        const apiResponse = await API.get(
          `/api/UsuarioConvenio/ObtemUsuarioPorConvenio/${id}`
        )

        /* if (this.user.idPerfil !== 1) {
          this.doctorsWithBounds = apiResponse.data.object.filter(
            (doctor) => doctor.id === this.user.id
          )
        } else { */
        this.doctorsWithBounds = apiResponse.data.object || []
        /* } */
      } catch (error) {}
    },
    async getDoctorsFromClinic() {
      try {
        if (this.user.idPerfil !== 1) {
          const apiResponse = await API.get(
            `/api/UsuarioClinica/ObtemUsuarioPorClinica/${this.user.clinica_usuario[0].idClinica}`
          )

          const doctors = apiResponse.data.object.map((doctor) => ({
            idConvenio: this.newHealthInsurance.id,
            idUsuario: doctor.idUsuario,
            nmUsuario: doctor.nmUsuario,
            idUsuarioInclusao: this.user.id
          }))

          this.doctorsFromClinic = doctors
        } else {
          const { idUsuario, nmUsuario } = this.user.clinica_usuario[0]

          const doctors = [
            {
              idConvenio: this.newHealthInsurance.id,
              idUsuario,
              nmUsuario,
              idUsuarioInclusao: this.user.id
            }
          ]

          this.doctorsFromClinic = doctors
        }
      } catch (error) {
        this.$toast.error('Erro ao obter médicos da clínica:', error)
      }
    },
    handlerShow(healthInsurance) {
      this.resetForm()

      if (healthInsurance) {
        this.newHealthInsurance = { ...healthInsurance }
        this.newHealthInsurance.dtCadastro = this.formatDate(
          this.newHealthInsurance.dtCadastro,
          'en-CA'
        )

        if (this.newHealthInsurance.dtFaturamento)
          this.newHealthInsurance.dtFaturamento = this.formatDate(
            this.newHealthInsurance.dtFaturamento,
            'en-CA'
          )
        this.isAdd = false
      } else {
        this.newHealthInsurance.ativo = 0
        this.doctorsFromClinic = []
        this.doctorsWithBounds = []
        this.isAdd = true
      }
      this.show = true
    },
    requests() {
      API.get('/api/TipoLogradouro/ObtemLista', this.authToken).then((res) => {
        this.adressTypes = res.data.object
      })

      API.get('/api/Estado/ObtemLista').then((res) => {
        this.states = res.data.object
      })
    },
    async getHealthInsurances() {
      await API.get(
        `/api/Convenio/ObtemListaPorClinica?id_clinica=${this.selectClinic.idClinica}`,
        this.authToken
      ).then((res) => {
        this.userHealthInsurances = res.data.object
        this.searchedHealthInsurances = res.data.object
      })
    },
    resetForm() {
      this.newHealthInsurance = {
        razaoSocial: '',
        cnpj: '',
        ativo: '',
        dtCadastro: new Date().toLocaleDateString('en-CA'),
        dtFaturamento: '',
        mascaraCartao: '',
        responsavel: '',
        idTipoLogradouro: '',
        cep: '',
        logradouro: '',
        numero: '',
        complementoEndereco: '',
        bairro: '',
        idEstado: '',
        idCidade: '',
        telCelular: '',
        telComercial: '',
        email: ''
      }
      this.selectedDoctor = {}
    },
    formatDate(date, format) {
      const newDateFormat = new Date(date)
      return newDateFormat.toLocaleDateString(format)
    },
    formattelCelular() {
      if (this.newHealthInsurance.telCelular) {
        const newtelCelular = this.newHealthInsurance.telCelular.replace(
          /[^0-9]/g,
          ''
        )
        this.newHealthInsurance.telCelular = newtelCelular
      }

      if (this.newHealthInsurance.telComercial) {
        const newtelComercial = this.newHealthInsurance.telComercial.replace(
          /[^0-9]/g,
          ''
        )
        this.newHealthInsurance.telComercial = newtelComercial
      }
    },
    formatCnpj() {
      if (this.newHealthInsurance.cnpj) {
        const newCnpj = cnpj.strip(this.newHealthInsurance.cnpj)
        this.newHealthInsurance.cnpj = newCnpj
      }
    },
    hideModal() {
      this.$refs['modal-confirm'].hide()
    },
    cancelar() {
      this.hideModal()
      this.show = false
    },
    getCities() {
      API.get(
        `/api/Cidade/ObtemCidadesPorEstado/${this.newHealthInsurance.idEstado}`
      ).then((res) => {
        this.cities = res.data.object
      })
    },
    searchHealthInsurance(filter) {
      filter
        ? (this.searchedHealthInsurances = this.userHealthInsurances.filter(
            (healthInsurance) =>
              healthInsurance.razaoSocial
                .toLowerCase()
                .includes(filter.toLowerCase())
          ))
        : (this.searchedHealthInsurances = this.userHealthInsurances)
    },
    async onSubmit() {
      this.formattelCelular()
      this.formatCnpj()
      this.newHealthInsurance.idUsuario = this.user.id
      /* this.newHealthInsurance.mascaraCartao = this.newHealthInsurance */

      const healthInsurances = this.userHealthInsurances.map((element) => {
        return {
          razaoSocial: element.razaoSocial,
          id: element.id
        }
      })

      const healthInsuranceAlreadyExists = healthInsurances
        .map((element) => (this.isManager ? element.id : element.razaoSocial))
        .indexOf(
          this.isManager
            ? this.newHealthInsurance.id
            : this.newHealthInsurance.razaoSocial
        )

      const doctorsToAdd = this.doctorsWithBounds.filter(
        (doctor) => doctor.isNew
      )

      try {
        const {
          /*  id, */
          razaoSocial,
          cnpj,
          dtCadastro,
          dtFaturamento,
          mascaraCartao,
          responsavel,
          idTipoLogradouro,
          cep,
          logradouro,
          numero,
          complementoEndereco,
          bairro,
          idEstado,
          idCidade,
          telCelular,
          telComercial,
          email,
          idUsuario,
          ativo
        } = this.newHealthInsurance
        const numNumberAdress = numero ? parseInt(numero) : null
        const numCep = cep ? cep.replace('-', '') : null
        const idClinica = this.selectClinic ? this.selectClinic.idClinica : null

        if (healthInsuranceAlreadyExists === -1) {
          const healthInsurance = await API.post(
            '/api/Convenio/Adicionar',
            {
              object: {
                razaoSocial,
                cnpj,
                dtCadastro,
                dtFaturamento,
                mascaraCartao,
                responsavel,
                telCelular,
                telComercial,
                email,
                idTipoLogradouro,
                cep: numCep,
                logradouro,
                numero: numNumberAdress,
                complementoEndereco,
                bairro,
                idEstado,
                idCidade,
                linkImagem: '',
                dtInclusao: '',
                idUsuarioInclusao: idUsuario,
                idUsuario: idUsuario,
                ativo,
                idClinica
              }
            },
            {
              headers: {
                Authorization: `Bearer ${window.$cookies.get('token')}`
              }
            }
          )
          if (!doctorsToAdd[0] && !this.isManager) {
            doctorsToAdd.push({
              idUsuario: this.user.id,
              nmUsuario: this.user.nome
            })
          }
          doctorsToAdd.forEach(async (doctor) => {
            const requestObject = {
              ativo: 0,
              idConvenio: healthInsurance.data.object.id,
              nmConvenio: healthInsurance.data.object.razaoSocial,
              idUsuario: doctor.idUsuario,
              nmUsuario: doctor.nmUsuario,
              dtCriacao: new Date().toISOString(),
              idUsuarioInclusao: this.user.id,
              temAtendimento: 'F'
            }
            await API.post('/api/UsuarioConvenio/Adicionar', {
              object: requestObject
            })
          })

          await this.getDoctorsBoundedWithHealthInsurance(
            healthInsurance.data.object.id
          )
          this.$toast.success('Convênio adicionado com sucesso')
        } else {
          await API.put(
            '/api/Convenio/Editar',
            {
              object: {
                id: healthInsurances[healthInsuranceAlreadyExists].id,
                razaoSocial,
                cnpj,
                dtCadastro,
                dtFaturamento,
                mascaraCartao,
                responsavel,
                idTipoLogradouro,
                cep: numCep,
                logradouro,
                numero: numNumberAdress,
                complementoEndereco,
                bairro,
                idEstado,
                idCidade,
                telCelular,
                telComercial,
                email,
                linkImagem: '',
                dtInclusao: '',
                idUsuarioInclusao: idUsuario,
                idUsuario: idUsuario,
                ativo,
                idClinica
              }
            },
            {
              headers: {
                Authorization: `Bearer ${window.$cookies.get('token')}`
              }
            }
          )

          this.$toast.success('Convênio alterado com sucesso')
        }

        this.getHealthInsurances()
      } catch (_) {
        this.$toast.error('Algo deu errado')
      }

      // this.$store
      //   .dispatch(
      //     `HealthInsurance/${this.isAdd ? 'add' : 'edit'}HealthInsuranceAction`,
      //     { ...this.newHealthInsurance, doctorsToAdd }
      //   )
      //   .then((healthInsurance) => {
      //     this.$toast.success('Convenio cadastrado com sucesso')
      //     this.getHealthInsurances()
      //   })
      //   // eslint-disable-next-line handle-callback-err
      //   .catch((err) => {
      //     this.$toast.error('Ocorreu algum erro, tente novamente!')
      //   })
    }
  }
}
</script>

<style scoped lang="scss">
#usersListContainer {
  display: flex;
  flex-direction: column;
}

#usersList {
  flex: 1;

  margin-bottom: 0;
}

.tab-bar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-bar button {
  cursor: pointer;
  border-radius: 20px;
  padding: 20px;
  gap: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-active {
  flex: 1;
  border: #089bab;
  color: #fff;
  background-color: #089bab;
}

.button-deactive {
  flex: 1;
  border: #fff;
  color: #089bab;
  background-color: #fff;
}
.select-clinic {
  border-radius: 10px;
  color: grey;
  background-color: #ceebee;
  border: #ceebee 1px solid;
  padding: 8px 12px;
  margin-bottom: 10px;
  width: 100%;
}

.select-clinic:focus {
  outline: none;
  border: 1px #089bab solid;
}

.onHover:hover {
  cursor: pointer;
}

.selected {
  background-color: #089bab !important;
  color: white;
}

.cardSameLevel {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sameLevel {
  display: flex;
  align-items: flex-end;
}

.iq-card_scoped {
  margin-bottom: 10px !important;
}

.radioOptions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.usersList {
  flex: 1;
  padding-right: 10px;
  overflow-x: auto;
  margin-bottom: 20px;
  max-height: 70vh;
  overflow-y: scroll;
}

.userListColumn {
  display: flex;
  flex-direction: column;
}

.form-iqcard {
  margin-bottom: 0 !important;
  overflow-x: none;
  overflow-y: none;
}

.photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.table {
  background: white;
  overflow-y: auto !important;
  border-radius: 6px !important;
}

.headerList {
  z-index: 1;
  position: sticky;
  top: 0;
  font-weight: 500;
  height: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-bottom: 2px solid #c4c8d1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #089bab;
  color: #fff;

  > :nth-child(3),
  > :nth-child(4),
  > :nth-child(5) {
    justify-self: center;
  }
}

.dataList {
  z-index: 2;
  font-weight: 400;
  height: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  border-bottom: 1px solid #d4dae4;
  padding: 10px;
  color: #3d5879;

  > :nth-child(3),
  > :nth-child(4) {
    justify-self: center;
  }
}

.buttons {
  margin-top: 50px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.buttonBottom {
  bottom: 0;
  right: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
